import React from 'react';
import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { SchoolLayout } from '../SchoolLayout';


const BSTeachingTeamPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(SchoolLayout, BSTeachingTeamPage); 
