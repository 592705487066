import React from 'react';


import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { PTSHomePageLayout } from '../PTSHomePageLayout';

const TheTeamPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(PTSHomePageLayout, TheTeamPage); 


