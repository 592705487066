import { IHomePageState } from "./HomePageModels";
import DigitalOfficialPage from "./HomeChildPage/DigitalOfficialPage/DigitalOfficialPage";
import ContactMunicipalPage from "./HomeChildPage/ContactMunicipalPage/ContactMunicipalPage";
import ChildcareEducationPage from "./HomeChildPage/ChildcareEducationPage/ChildcareEducationPage";
import WasteCollectionPage from "./HomeChildPage/WasteCollectionPage/WasteCollectionPage";
import ParkingPage from "./HomeChildPage/ParkingPage/ParkingPage";
import MedicalServicePage from "./HomeChildPage/MedicalServicePage/MedicalServicePage";
import DsgvoPage from "../DsgvoPage/DsgvoPage";
import ImprintPage from "../ImprintPage/ImprintPage";
import ErrorPage from "../ErrorPage/ErrorPage";

export const stateHomepage: IHomePageState = {
  masonryNews: [
    {date: "2022-10-15T07:15:00+02:00", titleNew: "Eröffnung der Kinderfreunde"},
    {date: "2022-10-21T07:15:00+02:00", titleNew: "Klarinettentage" },
    {date: "2022-11-04T07:15:00+02:00", titleNew: "Stelzenschnapsen" },
    {date: "2022-11-18T07:15:00+02:00", titleNew: "Punschstand am Hauptplatz"},

    {date: "2022-10-14T07:15:00+02:00", titleNew: "Eröffnung der Kinderfreunde"},
    {date: "2022-10-20T07:15:00+02:00", titleNew: "Klarinettentage" },
    {date: "2022-11-05T07:15:00+02:00", titleNew: "Stelzenschnapsen" },
    {date: "2022-09-19T07:15:00+02:00",titleNew: "Punschstand am Hauptplatz"},

    {date: "2022-12-14T07:15:00+02:00", titleNew: "Eröffnung der Kinderfreunde"},
    {date: "2023-01-20T07:15:00+02:00", titleNew: "Klarinettentage" },
    {date: "2023-01-05T07:15:00+02:00", titleNew: "Stelzenschnapsen" },
    {date: "2023-01-19T07:15:00+02:00",titleNew: "Punschstand am Hauptplatz"},

    {date: "2023-01-19T07:15:00+02:00",titleNew: "Punschstand am Hauptplatz"},
    // {date: "2023-01-19T07:15:00+02:00",titleNew: "Punschstand am Hauptplatz"},
    // {date: "2023-01-19T07:15:00+02:00",titleNew: "Punschstand am Hauptplatz"},
    
  ],
  boxLinks: [
    {
      uuid: "6C659A35-8273-4F49-9D70-7E5B379551CE",
      img: "/icons/medical-services.svg",
      label: "Gesundheits- dienste",
      link: "/gesundheitsdienste",
      component: (items, data, parent) => <MedicalServicePage parent={parent} items={items} data={data} />
    },
    {
      uuid: "D241F8B9-EAA1-42A8-BE47-0B2FC0932054",
      img: "/icons/parking.svg",
      label: "Parken",
      link: "/parken",
      component: (items, data, parent) => <ParkingPage parent={parent} items={items} data={data} />
    },
    {
      uuid: "900B4331-3B9B-4E58-A682-E4ABD8E9F181",
      img: "/icons/garbage-calendar.svg",
      label: "Müllkalender und Sammelstelle",
      link: "/müllkalender-und-sammelstelle",
      component: (items, data, parent) => <WasteCollectionPage parent={parent} items={items} data={data} />
    },
    {
      uuid: "ECBA08B2-6EDD-4BAF-AD5E-653303728B55",
      img: "/icons/contact-municipal-administration.svg",
      label: "Kinderbetreu- ung & Bildung",
      link: "/kinderbetreuung-bildung",
      component: (items, data, parent) => <ChildcareEducationPage parent={parent} items={items} data={data} />
    },
    {
      uuid: "6F7A69A7-829F-4D94-ADE9-CD3DB4FE1177",
      img: "/icons/contact.svg",
      label: "Kontakt-Stadtverwaltung",
      link: "/kontakt-stadtverwaltung",
      component: (items, data, parent) => <ContactMunicipalPage parent={parent} items={items} data={data} />

    },
    {
      uuid: "25C185BD-533C-45D8-9352-43A3F3EE0E0B",
      img: "/icons/digital-official-board.svg",
      label: "Digitale Amtstafel",
      link: "/digitale-amtstafel",
      component: (items, data, parent) => <DigitalOfficialPage parent={parent} items={items} data={data} />
    },
  ],
  other: [
    {
      uuid: "C0B8101A-07DD-48E5-81D8-873C9D1D59DE",
      img: "",
      label: "Impressum",
      link: "/impressum",
      component: (items, data, parent) => <ImprintPage parent={parent} items={items} data={data} />

    },
    {
      uuid: "8D078E29-1602-4E34-B65A-290E6CF9C7EA",
      img: "",
      label: "Datenschutz",
      link: "/datenschutz",
      component: (items, data, parent) => <DsgvoPage parent={parent} items={items} data={data} />
    },
    {
      uuid: "BAFE637A-B005-44C1-93C6-5AEEFCFAB8F6",
      img: "",
      label: "Error",
      link: "*",
      component: (items, data, parent) => <ErrorPage parent={parent} items={items} data={data} />
    },
  ],
  tileNews: [
    {
      id:1,
      link: "/news",
      img: ["/img-news/rectangle1.png", "/img-news/rectangle1.png"],
      description:
        "Oberwart ist mit 7.700 Einwohnern die größte Stadt des Südburgenlandes",
      descriptionAuthor: "Volles Programm",
      text: `Verwaltung! Daher haben wir uns dazu entschieden, eine neue Service-Plattform "People Connect" für Bürgerinnen und Bürger anzubieten. Somit werden diese aktiv in die Verwaltung eingebunden. Neben der Möglichkeit der Einsicht auf Daten und Dokumente können Sie auch Anliegen (Anbringen) schnell und unkompliziert über diese Plattform einbringen.`
    },
    {
      id:2,
      link: "/news",
      img: ["/img-news/rectangle2.png"],
      description:
        "Bier-Hamsterkäufe führen zu Leergut-Knappheit im Burgenland",
        text: `Verwaltung! Daher haben wir uns dazu entschieden, eine neue Service-Plattform "People Connect" für Bürgerinnen und Bürger anzubieten. Somit werden diese aktiv in die Verwaltung eingebunden. Neben der Möglichkeit der Einsicht auf Daten und Dokumente können Sie auch Anliegen (Anbringen) schnell und unkompliziert über diese Plattform einbringen.`
    },
    {
      id:3,
      link: "/news",
      img: ["/img-news/rectangle3.png"],
      description: "Oberwart hat sich als Wirtschaftsstandort",
      text: `Verwaltung! Daher haben wir uns dazu entschieden, eine neue Service-Plattform "People Connect" für Bürgerinnen und Bürger anzubieten. Somit werden diese aktiv in die Verwaltung eingebunden. Neben der Möglichkeit der Einsicht auf Daten und Dokumente können Sie auch Anliegen (Anbringen) schnell und unkompliziert über diese Plattform einbringen.`
    },
    {
      id:4,
      link: "/news",
      img: ["/img-news/rectangle4.png","/img-news/rectangle3.png"],
      description: "Bürgergeld: Die wichtigsten Fragen und Antworten",
      text: `Verwaltung! Daher haben wir uns dazu entschieden, eine neue Service-Plattform "People Connect" für Bürgerinnen und Bürger anzubieten. Somit werden diese aktiv in die Verwaltung eingebunden. Neben der Möglichkeit der Einsicht auf Daten und Dokumente können Sie auch Anliegen (Anbringen) schnell und unkompliziert über diese Plattform einbringen.`
    },
    {
      id:5,
      link: "/news",
      img: ["/img-news/rectangle5.png"],
      description:
        "„Hopp oder Top“: Leckage von Isar 2 befeuert Streit um Laufzeit",
        text: `Verwaltung! Daher haben wir uns dazu entschieden, eine neue Service-Plattform "People Connect" für Bürgerinnen und Bürger anzubieten. Somit werden diese aktiv in die Verwaltung eingebunden. Neben der Möglichkeit der Einsicht auf Daten und Dokumente können Sie auch Anliegen (Anbringen) schnell und unkompliziert über diese Plattform einbringen.`
    },
  ],
  flatNews: [
    {
      id:1,
      link: "/map-oberwart",
      img: "/img-news/rectangle6.png",
      description: "Stadtplan",
    },
    {
      id:2,
      link: "/service/gemeindenachrichten–oberwart-aktiv",
      img: "/img-news/rectangle7.png",
      description: "Oberwart aktiv",
    },
  ],
  boxListNews: [
    {
      title: "POLITIK",
      subtitle: "Alle News",
      listNews: [
        {
          path: "/",
          label: "Traineeprogramm 2023",
        },
        {
          path: "/",
          label: " Willkommens-Geschenk für Neugeborene",
        },
        {
          path: "/",
          label: "Eintragungswoche für Volksbegehren",
        },
        {
          path: "/",
          label: "Sprechstunde: Schule und Lernen",
        },
        {
          path: "/",
          label: "19. September: Servicestelle Schmiedgasse schließt früher",
        },
        {
          path: "/",
          label: "Infoveranstaltung:  Jugend(schutz)gesetz",
        },
        {
          path: "/",
          label: "Aktuelle Informationen zur Ukraine",
        },
      ],
    },

    {
      title: "BÜRGERINNEN-SERVICE",
      subtitle: "Stadtverwaltung",
      listNews: [
        {
          path: "/",
          label: "Oberwart würdigt Ivica Osim",
        },
        {
          path: "/",
          label: "Vorrang für die autofreie Stadt",
        },
        {
          path: "/",
          label: "Aufsteirern verbindet Tradition mit Moderne",
        },
        {
          path: "/",
          label: "Luisa ist da! startet in den Herbst",
        },
        {
          path: "/",
          label: "Aufschlag Tennis: Mit Ausbau in die Zukunft",
        },
        {
          path: "/",
          label: "66 neue Gemeindewohnungen übergeben",
        },
        {
          path: "/",
          label: "Aktuelles aus dem Stadtsenat",
        },
      ],
    },
  ],
};
