import React, {  useCallback, useEffect, useState } from "react";
import ContentPage from "../../../components/ContentPage/ContentPage";
import Loader from "../../../components/UI/Loader/Loader";
import Typography from "../../../components/UI/Typography/Typography";
import styles from "./NewsList.module.scss";
import {INews, NewsService} from "../../../service/news";
import {Config} from "../../../config/config";

type NewsListState = {
  loading: boolean;
  error: string | null;
};

const NewsList = () => {
  const [state, setState] = useState<NewsListState>({
    loading: true,
    error: null,
  });

  const [news, setNews] = useState<INews[]>([])
  const refresh = useCallback(async () => {
    setNews(await NewsService.get(Config.org, 20, 0, ['news']))
    setState({loading: false, error: null});
    // eslint-disable-next-line no-restricted-globals
  }, []);


  useEffect(() => {
    refresh();
  }, [refresh]);

  const {error,loading} = state
  return (
    <div className={styles.listNews}>
      {loading && <Loader/>}
      {(!loading && news && !error) && (
        <div>
          {news.map((n,i) => (
              <div className={styles.newsEntry}>
                <ContentPage
                    small
                    key={`NEW-N-${i+1}`}
                    data={n}
                    link={`/news/${n.id}`}
                />
              </div>
          ))}
        </div>
      )}
      {error && <Typography color="fume" >{error}</Typography>}
    </div>
  );
};

export default NewsList;
