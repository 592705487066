import React, { FC, ReactNode } from 'react';
import BoxLink from '../components/BoxLinks/BoxLinks';
import { IPageConfig } from '../models/page';


type AppEconomicYardHomePageLayoutProps = {
  children?: ReactNode | ReactNode[];
  items: IPageConfig[];
};

export const AppEconomicYardHomePageLayout:FC<AppEconomicYardHomePageLayoutProps> = ({children, items}) => {
  return (
    <div>
      <BoxLink items={items} hide={!!children}  />
      {children}
    </div>
  );
};
