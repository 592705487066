import classNames  from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styles from './Icon.module.scss'

interface IconProps {
  src: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ src,className = ''}) => {
  return  <ReactSVG  className={classNames(styles.icon, className, 'theme-color org-theme-svg')} src={src}  />
};

export default Icon;
