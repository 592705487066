import AustriaWebsitePage from "./DigitalChildPage/AustriaWebsitePage/AustriaWebsitePage";
import CitiesAppPage from "./DigitalChildPage/CitiesAppPage/CitiesAppPage";
import IdAustriaPage from "./DigitalChildPage/IdAustriaPage/IdAustriaPage";
import OnlineFormsPage from "./DigitalChildPage/OnlineFormsPage/OnlineFormsPage";
import PeopleConnectPage from "./DigitalChildPage/PeopleConnectPage/PeopleConnectPage";
import {IPageConfig} from "../../models/page";
import DigitalPage from "./DigitalPage";

export const stateDigitalPage:IPageConfig[] = [
  {
    uuid: "1FC0198D-D089-493B-B8B4-0BEAACFA2D8D",
    link: "/digital",
    label: "Digital",
    img: "",
    content: "",
    component: (items, data, parent) => <DigitalPage parent={parent} items={items} data={data}/>,
    children: [
      {
        uuid: "1AD4ECAE-42B2-44AB-A945-AD683315F7A3",
        link: "/digital/id-austria-handysignatur",
        label: "ID Austria & Handysignatur",
        img: "/icons/id-austria.svg",
        content: "",
        component: (items, data, parent) => <IdAustriaPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "57880055-1DA2-41C1-A7A0-49C04097C26B",
        link: "/digital/online-formulare",
        label: "Online-Formulare",
        img: "/icons/online-forms.svg",
        content: "",
        component: (items, data, parent) => <OnlineFormsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "0B2EE1CA-D978-4A76-BB1E-8B345BB6D28E",
        link: "/digital/oesterreich.gv.at",
        label: "oesterreich.gv.at",
        img: "/icons/austria-website.svg",
        content: "",
        component: (items, data, parent) => <AustriaWebsitePage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "BF2A02D5-B94B-42AB-819C-4423F7CF9AF3",
        link: "/digital/people-connect",
        label: "people connect",
        img: "/icons/people-connect.svg",
        content: "",
        component: (items, data, parent) => <PeopleConnectPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "FD054EF3-53A0-4180-92BC-B06EE3C4EF72",
        link: "/digital/cities-app",
        label: "CITIES-App ",
        img: "/icons/cities-app.svg",
        content: "",
        component: (items, data, parent) => <CitiesAppPage parent={parent} items={items} data={data}/>,
      },
    ]
  }
];