import React from "react";
import HomePageLayout from "../../HomePageLayout";
import {
  IGenericArticleProps,
  withGenericArticle,
} from "../../../Generic/withGenericArticle";

const ChildcareEducationPage: React.FC<IGenericArticleProps> = ({
  children,
}) => {
  return children;
};

export default withGenericArticle(HomePageLayout, ChildcareEducationPage);
