import classNames from "classnames";
import { createElement, FC, ReactElement } from "react";
import styles from "./Typography.module.scss";

interface ITypographyProps {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";
  children: string;
  uppercase?: boolean;
  className?: string;
  bold?: boolean;
  size?: "xx-large" | "larger" | "large" | "small" | "smaller" | 'xx-small'; // medium defaul
  color?: "fume" | "white" | 'black';
  align?: "start" | "center" | "end";
  dblock?: boolean;
  ellipsis?:boolean
}

const Typography: FC<ITypographyProps> = ({
  tag,
  children,
  uppercase,
  className,
  bold,
  color,
  size,
  align,
  dblock,
  ellipsis
}): ReactElement => {
  const tagElement = createElement(tag || "p", {
    className: classNames(styles.Typography, className,'theme-color', color, size, align, {
      uppercase,
      bold,
      dblock,
      ellipsis
    }),
    children,
  });
  return tagElement;
};

export default Typography;
