import classNames from "classnames";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import ModalWindow from "../../ModalWindow/ModalWindow";
import Typography from "../Typography/Typography";
import styles from "./Img.module.scss";

type ImgProps = {
  src: string;
  alt?: string;
  link?: string;
  description?: string;
  descriptionAuthor?: string;
  rounded?: boolean;
  screen?: boolean;

};

const Img: FC<ImgProps> = ({
  src,
  alt,
  link,
  description,
  descriptionAuthor,
  rounded,
  screen,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleScreen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setFullScreen(!fullScreen);
  };

  const img = (
    <>
      <img src={src} alt={alt || "IMG"} draggable="false"  onClick={(e) => !link && handleScreen(e)} />
      {screen && (
        <div onClick={(e) => handleScreen(e)} className={styles.fullScreen}>
          [ + ]
        </div>
      )}
      {description && (
        <div className={classNames(styles.description, 'theme-block')}>
          <Typography bold uppercase color="white" size="small">
            {description}
          </Typography>
          {descriptionAuthor && (
            <Typography
              className={styles.descriptionAuthor}
              color="white"
              size="smaller"
            >
              {descriptionAuthor}
            </Typography>
          )}
        </div>
      )}
    </>
  );

  const linkImg = (
    <Link className={styles.link} to={link || ""} >
      {img}
    </Link>
  );
  return (
    <div className={classNames(styles.img, 'bordered', { rounded })} >
      {link ? linkImg : img}
      {fullScreen && (
        <ModalWindow onClose={() => setFullScreen(!fullScreen)}>
          <div className={styles.fullScreenImg}>
            <img src={src} alt={alt || "IMG"} draggable="false" />
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default Img;
