import { LayoutProps } from "../models/layout";

export const oberwartLayoutState: LayoutProps = {
  theme: "OBERWART",
  header: {
    logo: "/logo-oberwart.png",
    topName: "webcam",
    headerSliderProps: {
      websiteName: "oberwart",
      navigation: [
        { path: "stadt", label: "Stadt" },
        { path: "leben", label: "Leben" },
        { path: "service", label: "Service" },
        { path: "digital", label: "Digital" },
      ],
      slider: "86A70C6F-73FE-4E76-9D10-2B9A296F7543",
      websiteLinks: [
        {
          path: "https://www.facebook.com/StadtgemeindeOberwart",
          icon: "/social-svg-icons/facebook.svg",
          alt: "facebook",
        },
        {
          path: "https://www.instagram.com/stadtgemeinde_oberwart",
          icon: "/social-svg-icons/instagram.svg",
          alt: "instagram",
        },
      ],
    }
   
  },
  footer: {
    columns: [
      {
        title: "Quicklinks",
        items: [
          {
            link: "/impressum",
            linkName: "Impressum",
          },
          {
            link: "/datenschutz",
            linkName: "Datenschutz",
          },
          {
            link: "/stadt/stadtverwaltung",
            linkName: "Stadtverwaltung",
          },
          {
            link: "/stadt/politik",
            linkName: "Politik",
          },
          {
            link: "/stadt/digitales-archiv",
            linkName: "Archiv",
          },
        ],
      },
      {
        title: "Weitere Websites",
        items: [
          {
            link: "/",
            linkName: "Wirtschaftshof",
            targetNew: true,
          },
          {
            link: "/",
            linkName: "Kinderkrippe",
            targetNew: true,
          },
          {
            link: "/",
            linkName: "Kindergarten",
            targetNew: true,
          },
          {
            link: "/",
            linkName: "Nachmittagsbetreuung",
            targetNew: true,
          },
          {
            link: "/",
            linkName: "Standesamtsverband",
            targetNew: true,
          },
        ],
      },
      {
        title: "Folgen Sie uns",
        items: [
          {
            link: "https://about.citiesapps.com/",
            linkName: "CITIES",
            targetNew: true,
          },
          {
            link: "https://www.facebook.com/StadtgemeindeOberwart",
            linkName: "Facebook",
            targetNew: true,
          },
          {
            link: "https://www.instagram.com/stadtgemeinde_oberwart",
            linkName: "Instagram",
            targetNew: true,
          },
        ],
      },
    ],
  },
};
