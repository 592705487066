


import React from 'react';
import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { AfternoonCareHomePageLayout } from '../AfternoonCareHomePageLayout';

const CareContactOpeningTimesPage: React.FC<IGenericArticleProps> = ({children}) => {
  return children
};

export default withGenericArticle(AfternoonCareHomePageLayout, CareContactOpeningTimesPage); 