import React from "react";
import ServicePageLayout from "../../ServicePageLayout";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";


const ServiceEventsPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(ServicePageLayout, ServiceEventsPage);
