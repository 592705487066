import {GET} from './http';

const API_URL = '/api/v2/web/members'

export interface IEventFile {
    name: string;
    publish: boolean
    mimetype: string
}

export interface IMemberParam {
    MAP: { z: number, lat: number, lon: number };
    ORT: string;
    FILE: { [md5: string]: IEventFile };
}


export interface IMemberFunction {
    dateFrom: string;
    dateTo: string;
    funcId: number;
    funcName: string;
    orgId: number;
    funcGroup: string;
    funcGroupSort: number;
    funcSort: any;
    orgName: string;
}


export interface IMemberOrganization extends Partial<IMemberFunction> {
    orgName: string;
}

export interface IMemberRole {
    to: string;
    from: string;
    name: string;
    group: string;
}

export interface IMember {
    uuid: string;
    name: string;
    email: string;
    phoneMobile: string;
    roles: IMemberRole[];
    show?: boolean;
}

export type TPeopleType = 'STADTRAT'|'GEMEINDERAT'|'AUSSCHUSS'|'BAU'|'KULTUR'|'SPORT'|'STANDORT'|'UMWELT'|'PRUEFUNG'|'AMTSLEITUNG'
export class MemberService {
    static async get(org: number, group: TPeopleType): Promise<IMember[]|null> {
        return GET(`${API_URL}/${group}`, org);
    }

    public static resolveProfileImage(uuid: string | undefined, disableCache = true) {
        if (uuid) {
            if (disableCache) {
                return `/api/v2/web/profile/image/${uuid}?v=${Date.now()}`;
            }
            return `/api/v2/web/profile/image/${uuid}`;
        }
        return undefined;
    }
}
