import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from '../layouts/MainLayout/Layout';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { crecheState } from '../states/creche';
import { generateRoutes } from '../utils/generateRoutes';
import CrecheHomePage from './pages/CrecheHomePage';
import { crecheHomePageState } from './pages/crecheHomePageState';



function AppCreche() {
  const root = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/"  element={<Layout layoutProps={crecheState} />}>
          <Route index path="/"  element={<CrecheHomePage />} />
          {/* Child pages of the main page */}
            {generateRoutes(crecheHomePageState.boxLinks).map((page) => (
              <Route key={page.path} path={page.path} element={page.component} />
            ))}
          {/* Child pages of the main page */}

        </Route>

      </>
    )
  );
  return (
    <div >
      <RouterProvider router={root} />
    </div>
  );
}

export default AppCreche;

//<Route path="*" element={ <ErrorPage/> }/>