import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentPage from '../../components/ContentPage/ContentPage';
import Loader from '../../components/UI/Loader/Loader';
import Typography from '../../components/UI/Typography/Typography';
import BoxImageNews from '../HomePage/BoxImageNews/BoxImageNews';
import styles from './NewsPage.module.scss'
import {INews, NewsService} from "../../service/news";
import {Config} from "../../config/config";

type NewsPageState = {
  loading: boolean;
  error: any | null;
  news: INews | null
}


const NewsPage = () => {
  const [state, setState] = useState<NewsPageState>({
    loading: true,
    error: null,
    news: null
  })

  const params = useParams() as { news: string };

  const refresh = useCallback(async () => {
    const id = +params.news;
    try {
        setState((pre) => ({ ...pre,  loading: true, error:null, news:null }))
        const news = await NewsService.getOne(Config.org, id);
        setState((pre) => ({ ...pre, news, loading: false }))
    } catch (e) {
      setState((pre) => ({ ...pre, news: null, error: e, loading: false }))
    }
  }, [params.news]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const {error,news,loading} = state

  return (
    <div className={styles.news}>
        {loading && <Loader/>}
        {(!loading && news && !error) && (
          <>
            <ContentPage data={news}/>
            <div className={styles.newsSpace}/>
            <BoxImageNews flat categories={['news', 'breaking']} />
          </>
        )}
        {error && <Typography color="fume" >{error}</Typography>}
    </div>
  );
};

export default NewsPage;