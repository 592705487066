import classNames from "classnames";
import React, { useState } from "react";
import {  Outlet } from "react-router-dom";
import Container from "../../components/Container/Container";
import Cookie from "../../components/Cookie/Cookie";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { LayoutProps } from "../../models/layout";
import styles from "./Layout.module.scss";

const Layout:React.FC<{layoutProps: LayoutProps}> = ({layoutProps}) => {
  const [state, setState] = useState<boolean>(localStorage.getItem('cookie') !== 'confirmed')

  const cookieAgreeHandler = () => {
      setState(false)
      localStorage.setItem('cookie', 'confirmed')
  }
  return (
    <div className={styles.layout}>
      {state &&  <Cookie agreement={cookieAgreeHandler}/>}
      <Header headerProps={layoutProps.header} />
      <main className={classNames(styles.layoutMain, 'theme-block bordered')}>
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer columns={layoutProps.footer.columns} theme={layoutProps.theme}  />
    </div>
  );
};

export default Layout;
