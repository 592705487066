import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from '../layouts/MainLayout/Layout';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { afternoonCareState } from '../states/afternoonCare';
import { generateRoutes } from '../utils/generateRoutes';
import AfternoonCareHomePage from './pages/AfternoonCareHomePage';
import { afternoonCareHomePageState } from './pages/afternoonCareHomePageState';



function AppAfternoonCare() {
  const root = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/"  element={<Layout layoutProps={afternoonCareState} />}>
          <Route index path="/"  element={<AfternoonCareHomePage />} />
          {/* Child pages of the main page */}
            {generateRoutes(afternoonCareHomePageState.boxLinks).map((page) => (
              <Route key={page.path} path={page.path} element={page.component} />
            ))}
          {/* Child pages of the main page */}
        </Route>

      </>
    )
  );
  return (
    <div >
      <RouterProvider router={root} />
    </div>
  );
}

export default AppAfternoonCare;
//<Route path="*" element={ <ErrorPage/> }/>