import classNames from "classnames";
import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  Children,
} from "react";
import styles from "./Slider.module.scss";
import Next from "../../images/next.svg";
import Prev from "../../images/prev.svg";

type SliderState = {
  items: ReactNode[];
  current: number;
  transition: boolean;
  stopAuto: boolean;
};

type SliderProps = {
  dotsNavigation?: boolean;
  dotsNavigationPosition?: "top" | "bottom";
  buttonNavigation?: boolean;
  auto?: boolean;
  children: ReactNode | ReactNode[];
};

const Slider: FC<SliderProps> = ({
  dotsNavigation,
  dotsNavigationPosition,
  buttonNavigation,
  auto,
  children,
}) => {
  const [state, setState] = useState<SliderState>({
    items: [],
    current: 1,
    transition: false,
    stopAuto: false,
  });
  const sumImages = state.items.length;

  useEffect(() => {
    const arrayChildren = Children.toArray(children);
    const _items = [
      ...arrayChildren.slice(-1),
      ...arrayChildren,
      ...arrayChildren.slice(0, 1),
    ];
    setState((pre) => ({ ...pre, items: _items }));
  }, [children]);

  const handleSliderTranslateEnd = () => {
    setState((pre) => {
      const lastImg = pre.current === sumImages - 1
      const preFirstImg = pre.current === 0
      return ({
        ...pre,
        transition: false,
        current:
          lastImg
            ? 1
            : preFirstImg
            ? sumImages - 2
            : pre.current,
        stopAuto: !pre.stopAuto ? lastImg : true
      })
    });
  };

  

  const handlePrev = () => {
    if (state.current === 0 || state.transition) return;
    setState((prev) => ({
      ...prev,
      current: prev.current - 1,
      transition: true,
    }));
  };
  const handleNext = () => {
    if (state.current === sumImages - 1 || state.transition) return;
    setState((prev) => ({
      ...prev,
      current: prev.current + 1,
      transition: true,
    }));
  };
  const sliderStyle = useCallback(() => {
    if (state.transition) {
      return {
        transform: `translateX(${-((100 / sumImages) * state.current)}%)`,
        transition: "500ms ease-in-out",
      };
    }
    return {
      transform: `translateX(${-((100 / sumImages) * state.current)}%)`,
    };
  }, [state, sumImages]);

  useEffect(() => {
    if (state.current === sumImages - 1 || state.transition || state.stopAuto)
      return;
    if (auto) {
      const autoSlider = setInterval(() => {
        setState((prev) => ({
          ...prev,
          current: prev.current + 1,
          transition: true,
        }));
      }, 5000 * 2);
      return () => clearInterval(autoSlider);
    }
  }, [auto, state, sumImages]);

  return (
    <div className={styles.slider}>
      <div
        onTransitionEnd={handleSliderTranslateEnd}
        style={{ width: `${100 * sumImages}%`, ...sliderStyle() }}
        className={styles.sliderTape}
      >
        {state.items.map((item, i) => (
          <div key={`img-${i + 1}`} className={styles.sliderTapeItem}>
            {item}
          </div>
        ))}
      </div>

      {buttonNavigation && (
        <>
          <button className={styles.sliderPrevBtn} onClick={handlePrev}>
            <img src={Prev} alt="prev" draggable="false" />
          </button>
          <button className={styles.sliderNextBtn} onClick={handleNext}>
            <img src={Next} alt="next" draggable="false" />
          </button>
        </>
      )}
      {dotsNavigation && (
        <ul
          className={classNames(
            styles.sliderDotsNavigation,
            dotsNavigationPosition
          )}
        >
          {Array.from({ length: sumImages - 2 }).map((_, i) => (
            <li
              className={classNames(styles.sliderDotsNavigationItem, {
                active: i + 1 === state.current,
              })}
              onClick={() => {
                setState((pre) => ({
                  ...pre,
                  current: i + 1,
                  transition: true,
                }));
              }}
              key={i}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(Slider);
