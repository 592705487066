import classNames from "classnames";
import React, { FC } from "react";
import { FooterProps } from "../../models/footer";
import { Theme } from "../../models/layout";
import Container from "../Container/Container";
import Typography from "../UI/Typography/Typography";
import styles from "./Footer.module.scss";

const Footer:FC<{columns:FooterProps['columns'], theme: Theme}> = ({columns, theme}) => {
  return (
    <div className={classNames(styles.footer, theme, 'theme-block')}>
      <Container>
        <div className={styles.footerRow}>
          {columns.map((block, i) => (
            <div key={block.title} className={styles.footerRowBlock}>
              <Typography
                className={styles.footerRowBlockTitle}
                tag="h3"
                bold
                color="white"
                uppercase
              >
                {block.title}
              </Typography>
              <ul className={styles.footerRowBlockList}>
                {block.items.map((item) => (
                  <li className={styles.footerRowBlockListItem} key={item.linkName}>
                    <a className={styles.footerRowBlockListItemLink} href={item.link} target={item.targetNew ? "_new": undefined}>
                      <Typography tag="span" color="white">
                        {item.linkName}
                      </Typography>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Footer;
