import classNames from "classnames";
import React, {FC, useCallback, useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {HeaderProps} from "../../../models/header";
import Container from "../../Container/Container";
import Slider from "../../Slider/Slider";
import Img from "../../UI/Img/Img";
import Typography from "../../UI/Typography/Typography";
import styles from "./HeaderSlider.module.scss";
import {EFileVersion, INews, NewsService} from "../../../service/news";
import {Config} from "../../../config/config";

const HeaderSlider:FC<{headerSliderProps:HeaderProps['headerSliderProps']}> = ({headerSliderProps}) => {
  const {navigation,slider,websiteLinks,websiteName} = headerSliderProps
    const [article, setArticle] = useState<INews[]|null>(null)
    const refresh = useCallback(async () => {
        setArticle(await NewsService.getArticle(Config.org, slider))
    }, [slider]);

    useEffect(() => {
        refresh();
    }, [refresh]);

  return (
    <div className={classNames(styles.headerSlider )}>
      <div className={styles.headerSliderTop}>
        <Container className={styles.headerSliderTopContainer}>
          <ul className={styles.headerSliderTopContainerItems}>
            {websiteLinks.map((w) => (
              <li
                key={w.path}
                className={styles.headerSliderTopContainerItemsItem}
              >
                <a
                  className={styles.headerSliderTopContainerItemsItemLink}
                  href={w.path}
                  target="_blank"
                >
                  <img
                    className={styles.headerSliderTopContainerItemsItemLinkImg}
                    src={w.icon}
                    alt={w.alt}
                  />
                </a>
              </li>
            ))}
          </ul>
        </Container>
      </div>
      <div className={classNames(styles.navigation, 'theme-block-rgba org-theme-rgba-block')}>
        <Container className={styles.navigationContainer}>
          <div className={styles.navigationContainerTitle}>
            <Link to="/">
              <Typography tag="span" size="larger" uppercase color="white">
                {websiteName}
              </Typography>
            </Link>
          </div>
          <ul className={styles.navigationContainerList}>
            {navigation.map((nav) => (
              <li key={nav.label} className={styles.navigationContainerListItem}>
                <NavLink
                  className={styles.navigationContainerListItemLink}
                  to={nav.path}
                >
                  <Typography color="white" tag="span" size="large">
                    {nav.label}
                  </Typography>
                </NavLink>
              </li>
            ))}
          </ul>
        </Container>
      </div>
      <Slider buttonNavigation dotsNavigation auto>
        {article?.find(()=>true)?.attachedFiles.map((item, i) => (
          <Img key={`${item}-${i + 1}`} src={NewsService.getFileUrl(item, EFileVersion.md)} alt={item.fileName} />
        ))}
      </Slider>
    </div>
  );
};

export default HeaderSlider;
