import React from "react";
import DatePicker from "../../components/UI/DatePicker/DatePicker";
import Typography from "../../components/UI/Typography/Typography";
import styles from "./EventDay.module.scss";

interface IDataEventDay {
  title: string;
  children: {
    time: string;
    name: string;
  }[];
}

const EventDay = () => {
  const data: IDataEventDay[] = [
    {
      title: "Dreifachsporthalle",
      children: [
        {
          time: "7:00 - 16:30",
          name: "Schulbetrieb durch Bundesschulen",
        },
        {
          time: "7:00 - 16:30",
          name: "Gunners Heimspiel",
        },
      ],
    },
    {
      title: "NMS Funcourt",
      children: [
        {
          time: "7:00 - 16:00",
          name: "Schulbetrieb durch Bundesschulen",
        },
        {
          time: "7:00 - 18:0h0",
          name: "Gunners Nachwuchstraining",
        },
      ],
    },
    {
      title: "NMS Gymnastiksaal",
      children: [
        {
          time: "7:00 - 16:00",
          name: "NMS Schulbetrieb",
        },
      ],
    },
    {
      title: "NMS Turnsaal  groß",
      children: [
        {
          time: "7:00 - 17:00",
          name: "NMS Schulbetrieb",
        },
        {
          time: "7:00 - 16:00",
          name: "SVO Nachwuchs",
        },
      ],
    },
    {
      title: "VS Gymnastiksaal",
      children: [
        {
          time: "7:00 - 13:30",
          name: "Schulbetrieb",
        },
        {
          time: "7:00 - 16:30",
          name: "ASKÖ RGTB",
        },
        {
          time: "7:00 - 13:30",
          name: "UTTC Oberwarth",
        },
      ],
    },
    {
      title: "VS Gymnastiksaal",
      children: [
        {
          time: "7:00 - 13:30",
          name: "Schulbetrieb",
        },
        {
          time: "7:00 - 16:30",
          name: "ASKÖ RGTB",
        },
        {
          time: "7:00 - 13:30",
          name: "UTTC Oberwarth",
        },
        {
          time: "7:00 - 13:30",
          name: "UTTC Oberwarth",
        },
      ],
    },
  ];

  return (
    <div className={styles.eventDay}>
      <div className={styles.eventDayTitle}>
        <Typography tag="span" uppercase size="large">
          AKTUELLE
        </Typography>
        <Typography tag="span" uppercase bold size="large">
          &nbsp;
          Veranstaltungen
        </Typography>
      </div>
      <DatePicker onChange={(evt) => {}} />

      <div className={styles.eventDayLocations}>
        <Typography align="center" uppercase size="large">
          Mittwoch 14 Dezember 2022
        </Typography>

        <div  className={styles.row}>
          {data.map((loc, indexLoc) => (
            <div style={{ width: `${100 / data.length}%` }} className={styles.location}>
              <div className={styles.locationName}>{loc.title}</div>
              <div className={styles.locationEventList}>
                {loc.children.map((child, indexChild) => (
                  <div
                    key={`CHILD-${indexLoc + 1}${indexChild + 1}`}
                    className={styles.locationEventListEvent}
                  >
                    <div className={styles.locationEventListEventTime}>
                      {child.time}
                    </div>
                    <div className={styles.locationEventListEventName}>
                      {child.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventDay;
