import React, {useCallback, useEffect, useState} from "react";
import {CityPageLayout} from "../../CityPageLayout";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import {INews, NewsService} from "../../../../service/news";
import {Config} from "../../../../config/config";
import styles from "../../../LifePage/LifeChildPage/LibraryPage/LibraryPage.module.scss";
import ContentPage from "../../../../components/ContentPage/ContentPage";

const ArchivePage: React.FC<IGenericArticleProps> = ({children}) => {
  const [news, setNews] = useState<INews[]>([])
  const refresh = useCallback(async () => {
    setNews(await NewsService.get(Config.org, 20, 0, ['archiv']))
    // eslint-disable-next-line no-restricted-globals
  }, []);


  useEffect(() => {
    refresh();
  }, [refresh]);

  return (<>
    {children}
    {news.map((n,i) => (
        <div className={styles.newsEntry}>
          <ContentPage
              small
              key={`NEW-N-${i+1}`}
              data={n}
              link={`/news/${n.id}`}
          />
        </div>
    ))}
  </>);
}

export default withGenericArticle(CityPageLayout, ArchivePage);
