import React from 'react';
import {CityPageLayout} from './CityPageLayout';
import {IGenericArticleProps, withGenericArticle} from "../Generic/withGenericArticle";

const CityPage: React.FC<IGenericArticleProps> = ({children}) => {
  return (
      <>{children}</>
  );
};

export default withGenericArticle(CityPageLayout, CityPage);