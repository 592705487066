

import React from 'react';
import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { CrecheHomePageLayout } from '../CrecheHomePageLayout';

const CrecheContactOpeningTimesPage: React.FC<IGenericArticleProps> = ({children}) => {
  return children
};

export default withGenericArticle(CrecheHomePageLayout, CrecheContactOpeningTimesPage); 