import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./EventPage.module.scss";
import Typography from "../../components/UI/Typography/Typography";
import ContentPage from "../../components/ContentPage/ContentPage";
import EventsPage from "../EventsPage/EventsPage";
import Loader from "../../components/UI/Loader/Loader";
import {INews, NewsService} from "../../service/news";
import {Config} from "../../config/config";

interface IEventPageProps {}

interface IEventPageState {
  loading: boolean;
  error: any | null;
  event: INews | null;
}

const EventPage: React.FC<IEventPageProps> = () => {
  const [state, setState] = useState<IEventPageState>({
    loading: true,
    error: null,
    event: null,
  });
  const params = useParams() as { event: string };
  const refresh = useCallback(async () => {
    const id = +params.event;
    try {
      setState((pre) => ({ ...pre,  loading: true, error:null, news:null }))
      const event = await NewsService.getOne(Config.org, id);
      setState((pre) => ({ ...pre, event, loading: false }))
    } catch (e) {
      setState((pre) => ({ ...pre, event: null, error: e, loading: false }))
    }
  }, [params.event]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const { error, event, loading } = state;

  return (
    <div className={styles.eventPage}>
      {loading && <Loader />}
      {!loading && event && !error && (
        <EventsPage>
          <ContentPage
            data={event}
          />
        </EventsPage>
      )}
      {error && <Typography color="fume">{error}</Typography>}
    </div>
  );
};

export default EventPage;
