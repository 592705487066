import React from "react";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import {CityPageLayout} from "../../CityPageLayout";


const EconomicYardPage: React.FC<IGenericArticleProps> = ({children}) =>{
  return <>{children}</>
};

export default withGenericArticle(CityPageLayout, EconomicYardPage);
