import {GET} from './http';

const API_URL = '/api/v2/web/news'

export const enum EFileVersion {
    original,
    md,
    sm,
    xs
}

export interface IFileParam {
    createdAt: string;
    mime: string;
    name: string;
    original: string;
    thumbnails: {
        xs: string;
        sm: string;
        md: string;
    }
}

export interface IFile {
    fileChksum: string;
    fileId: number;
    fileName: string;
    fileOrgId: number;
    fileParam: IFileParam;
}

export interface INews {
    id: number,
    abstract: string,
    attachedFiles: IFile[];
    content: string;
    createdAt: string;
    lang: string;
    mgId: number;
    newscategory: string[];
    orgId: number;
    publishedAt: string;
    title: string;
    updatedAt: string;
    uuid: string;
    from: string;
    to: string;
    place: string;
}




export class NewsService {

    static async get(org: number, limit: number = 3, offset: number = 0, category: string[]|null = null): Promise<INews[]> {

        const categoryQuery = category ? `&category=${category.join(',')}` : '';
        return await GET(`${API_URL}?limit=${limit}&offset=${offset}${categoryQuery}`, org) || [];
    }

    static async find(org: number, limit: number, offset: number, text: string): Promise<INews[]> {
        return await GET(`${API_URL}/search?limit=${limit}&offset=${offset}&v=${text}`, org) || [];
    }

    static async getArticle(org: number, uuid: string): Promise<INews[]> {
        return await GET(`${API_URL}?uuid=${uuid}`, org) || []
    }

    static async getOne(org: number, id: number): Promise<INews|null> {
        return GET(`${API_URL}/detail/${id}`, org);
    }

    static getFileUrl(file: IFile, version: EFileVersion) {
        switch (version) {
            case EFileVersion.original:
                return `/api/v2/web/upload?file=${file?.fileName}`;
            case EFileVersion.md:
                return `/api/v2/web/upload?file=${file?.fileParam?.thumbnails?.md}`;
            case EFileVersion.sm:
                return `/api/v2/web/upload?file=${file?.fileParam?.thumbnails?.sm}`;
            case EFileVersion.xs:
                return `/api/v2/web/upload?file=${file?.fileParam?.thumbnails?.xs}`;
        }
    }
}