import classNames from 'classnames';
import React, {FC,ReactNode, HTMLProps} from 'react';
import styles from './Container.module.scss'


type ContainerProps = HTMLProps<HTMLDivElement> & {
   children: ReactNode | ReactNode[];
   className?: string
}

const Container:FC<ContainerProps> = ({children, className}) => {
   
   return (
      <div className={classNames(styles.container,  className)}>
         {children}
      </div>
   );
};

export default Container;