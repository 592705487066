import React, { FC } from 'react';

import classNames from "classnames";
import styles from './Event.module.scss'
import { Link } from 'react-router-dom';
import Typography from '../../../components/UI/Typography/Typography';
import { months } from '../../../utils/months';
import { weeks } from '../../../utils/weeks';
import moment from 'moment';
import {INews} from "../../../service/news";

type EventProps = {
  className?: string;
  data: INews;
}

const Event:FC<EventProps> = ({className, data}) => {
  const getWeekDay = (date: Date) => date.getDay() - 1 < 0 ? date.getDay() : date.getDay() - 1

  const startDate = new Date(data.from)
  const startDateTime = data.from && data.from.split("T")[1].slice(0,5)
  const endDate = data.to ? new Date(data.to) : null
  const endDateTime = data.to ? data.to.split("T")[1].slice(0,5) : ''

  return (
    <Link to={`/events/${data.id}`} className={classNames(styles.event, className, 'theme-block bordered')}>
      <div className={styles.eventDate}>
        <Typography bold align='center' className={styles.eventDateNumber}>
          {String(startDate.getDate())}
        </Typography>
        <Typography bold align='center' className={styles.eventDateMonth}>
          {String(months[startDate.getMonth()]?.slice(0,3))}
        </Typography>
      </div>
      <div className={styles.eventBody}>
        <Typography bold align='start' className={styles.eventBodyTitle}>
          {data.title}
        </Typography>
        <Typography  align='start' size='smaller' color='fume' className={styles.eventBodyDates}>
          {`
            ${endDate ? 'von' : 'am'} ${weeks[getWeekDay(startDate)]} ${moment(startDate).format('DD. MM. YYYY')} um ${startDateTime}
            ${endDate ? `bis ${weeks[getWeekDay(endDate)]} ${moment(endDate).format('DD. MM. YYYY')},${endDateTime}` : ''}
          `}
        </Typography>
      </div>
    </Link>
  );
};

export default Event;