/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";


import styles from "./MapPage.module.scss";

const MapPage = () => {
 
  return (
    <div className={styles.mapPage}>
      <iframe  src="https://oberwart.map2web.eu/poi/516423?parent=37329&location=1804798,5989560,17&embedded=true" />
    </div>
  );
};

export default MapPage;
