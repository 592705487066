import React from "react";
import {CityPageLayout} from "../../CityPageLayout";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";

const CityAdministration: React.FC<IGenericArticleProps> = ({children}) => {
  return <>
    {children}
  </>
};

export default withGenericArticle(CityPageLayout, CityAdministration);
