import styles from "./BoxListNews.module.scss";
import {FC, ReactElement} from "react";

const BoxListNewsContainer: FC<{children: ReactElement | ReactElement[]}> = ({ children }) => {
    return (
        <div className={styles.content}>
            {children}
        </div>
    );
}

export default BoxListNewsContainer;