import React from "react";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import ServicePageLayout from "../../ServicePageLayout";


const PsychologistsPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(ServicePageLayout, PsychologistsPage);
