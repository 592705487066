import React from "react";
import {IGenericArticleProps, withGenericArticle} from "../Generic/withGenericArticle";
import {CityPageLayout} from "../CityPage/CityPageLayout";


const ImprintPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(CityPageLayout, ImprintPage);
