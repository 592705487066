
import React from 'react';
import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { GeneralSchoolLayout } from '../GeneralSchoolLayout';


const ASOOrganizationPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(GeneralSchoolLayout, ASOOrganizationPage); 