import React, { useCallback, useEffect, useState } from "react";
import BoxLink from "../../components/BoxLinks/BoxLinks";
import BoxImageNews from "./BoxImageNews/BoxImageNews";
import BoxListNews from "../../components/BoxListNews/BoxListNews";
import styles from "./HomePageLayout.module.scss";
import { stateHomepage } from "./stateHomePage";
import FlatLinks from "../../components/FlatLinks/FlatLinks";
import BoxListNewsContainer from "../../components/BoxListNews/BoxListNewsContainer";
import { EFileVersion, INews, NewsService } from "../../service/news";
import { Config } from "../../config/config";
import BoxEvents from "./BoxEvents/BoxEvents";

const HomePage = () => {
  const [news, setNews] = useState<INews[]>([]);
  const refresh = useCallback(async () => {
    setNews(await NewsService.get(Config.org, 2, 0, ["news", "slider"]));
    // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, window.location.pathname]);

  const { flatNews, boxLinks } = stateHomepage;
  return (
    <div className={styles.home}>
      <BoxLink items={boxLinks} />
      <div className={styles.homeTileNews}>
        <div className={styles.homeTileNewsItem}>
          <BoxEvents />
        </div>
        <div className={styles.homeTileNewsItem}>
          <BoxImageNews categories={["news", "breaking"]} />
        </div>
      </div>
      <div className={styles.homeNews}>
        <div className={styles.homeNewsItem}>
          <FlatLinks
            items={news.map((n) => ({
              id: n.id,
              link: `/news/${n.id}`,
              img:
                n?.attachedFiles?.length > 0
                  ? NewsService.getFileUrl(n.attachedFiles[0], EFileVersion.md)
                  : "",
              description: n.title,
            }))}
          />
        </div>
        <div className={styles.homeNewsItem}>
          <FlatLinks items={flatNews} />
        </div>
      </div>
      <BoxListNewsContainer>
        <BoxListNews
          title="Gut zu wissen"
          categories={["news", "breaking", "wissen"]}
          offset={4}
          limit={7}
        />
        <BoxListNews
          title="Neues aus der Stadt"
          categories={["news", "breaking", "neues"]}
          offset={4}
          limit={7}
        />
      </BoxListNewsContainer>
      
    </div>
  );
};

export default HomePage;
