
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from '../layouts/MainLayout/Layout';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { kindergartenState } from '../states/kindergarten';
import { generateRoutes } from '../utils/generateRoutes';
import KindergartenHomePage from './pages/KindergartenHomePage';
import { kindergartenHomePageState } from './pages/kindergartenState';



function AppKindergarten() {
  const root = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/"  element={<Layout layoutProps={kindergartenState} />}>
          <Route index path="/"  element={<KindergartenHomePage />} />
          {/* Child pages of the main page */}
            {generateRoutes(kindergartenHomePageState.boxLinks).map((page) => (
              <Route key={page.path} path={page.path} element={page.component} />
            ))}
          {/* Child pages of the main page */}
        </Route>

      </>
    )
  );
  return (
    <div >
      <RouterProvider router={root} />
    </div>
  );
}

export default AppKindergarten;
//<Route path="*" element={ <ErrorPage/> }/>