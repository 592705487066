import React, { Fragment } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import styles from "./Breadcrumbs.module.scss";

function capitalizeFirstLetter(str: string): string {
  const words: string[] = str.split("-");
  const capitalizedWords: string[] = words.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}

const Breadcrumbs = () => {
  const location: Location = useLocation();
  const pathnames: string[] = decodeURIComponent(location.pathname)
    .split("/")
    .filter((x: string) => x);
  const goBack: boolean = pathnames.length === 1;
  const links = pathnames.map((name: string, index: number) => {
    const routeTo: string = `/${pathnames.slice(0, index + 1).join("/")}`;
    const isLast: boolean = index === pathnames.length - 1;
    return isLast ? (
      <li key={name}>
        {goBack ? (
          <Link to={"/"} replace>
            zurück
          </Link>
        ) : (
          capitalizeFirstLetter(name)
        )}
      </li>
    ) : (
      <Fragment key={name}>
        <li className={styles.breadCrumbsListItem}>
          <Link to={routeTo}>{capitalizeFirstLetter(name)}</Link>
        </li>
        <span className={styles.breadCrumbsListArrow}>{">>"}</span>
      </Fragment>
    );
  });

  return (
    <div className={styles.breadCrumbs}>
      <ul className={styles.breadCrumbsList}>{links}</ul>
    </div>
  );
};

export default Breadcrumbs;
