import React from "react";
import {IGenericArticleProps, withGenericArticle} from "../Generic/withGenericArticle";
import DigitalPageLayout from "../DigitalPage/DigitalPageLayout";

const ErrorPage: React.FC<IGenericArticleProps> = ({children}) => {
  return (
    <div>
        {children}
    </div>
  );
};

export default withGenericArticle(DigitalPageLayout, ErrorPage);
