import classNames from "classnames";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { weeks } from "../../../utils/weeks";
import { months } from "../../../utils/months";
import Typography from "../Typography/Typography";
import FromToArrow from '../../../images/from-to-arrow.png';
import LeftArrow from '../../../images/left.svg';
import DoubleLeftArrow from '../../../images/double-left.svg';
import RightArrow from '../../../images/right.svg';
import DoubleRightArrow from '../../../images/double-right.svg';
import styles from "./DatePicker.module.scss";
import moment from "moment";

type Day = {
  day: number;
  fullDate: string;
  inActice: boolean;
};

type DatePickerState = {
  currentDate: {
    currYear: number;
    currMonth: number;
  };
  days: Day[];
  today: string;
  selectedDate: string | string[] | null;
  calendarVisibility: boolean;
};

type DatePickerProps = {
  onChange: (value:  string | string[] | null) => void;
  rangePicker?: boolean;
};

const DatePicker: FC<DatePickerProps> = ({ onChange, rangePicker }) => {
  const [state, setState] = useState<DatePickerState>({
    currentDate: {
      currYear: new Date().getFullYear(),
      currMonth: new Date().getMonth(),
    },
    days: [],
    today: new Date().toISOString().split("T")[0],
    selectedDate: null,
    calendarVisibility: false,
  });

  const generateСalendar = useCallback((): void => {
    const {
      currentDate: { currMonth, currYear },
    } = state;
    const days: Day[] = [];
    let firsDayOfMoth = new Date(currYear, currMonth, 0).getDay(); // getting firs day of month
    let lastDateOfMonth = new Date(currYear, currMonth + 1, 0).getDate(); // getting last date of month
    let lastDayOfMonth = new Date(
      currYear,
      currMonth,
      lastDateOfMonth
    ).getDay(); // getting last day of month
    let lastDateOfLastMonth = new Date(currYear, currMonth, 0).getDate(); // getting last date of previous month

    for (let i = firsDayOfMoth; i > 0; i--) {
      days.push({
        day: lastDateOfLastMonth - i + 1,
        fullDate: moment(new Date( currYear,
          currMonth - 1,
          lastDateOfLastMonth - i + 1)).format('YYYY-MM-DD'),
        inActice: true,
      });
    }
    for (let i = 1; i <= lastDateOfMonth; i++) {
      days.push({
        day: i,
        fullDate: moment(new Date(currYear, currMonth, i)).format('YYYY-MM-DD'),
        inActice: false,
      });
    }
    for (let i = lastDayOfMonth; i < 7; i++) {
      days.push({
        day: i - lastDayOfMonth + 1,
        fullDate: moment(new Date(currYear, currMonth + 1, i - lastDayOfMonth + 1)).format('YYYY-MM-DD'),
        inActice: true,
      });
    }
    setState((pre) => ({ ...pre, days }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentDate]);

  useEffect(() => {
    generateСalendar();
    
  }, [generateСalendar, onChange]);


  useEffect(() => {
    onChange(state.selectedDate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ state.selectedDate])

  const handlerNextMonth = (): void => {
    const {
      currentDate: { currMonth, currYear },
    } = state;
    if (currMonth + 1 === 12) {
      setState((pre) => ({
        ...pre,
        currentDate: {
          ...pre.currentDate,
          currMonth: 0,
          currYear: currYear + 1,
        },
      }));
    } else {
      setState((pre) => ({
        ...pre,
        currentDate: {
          ...pre.currentDate,
          currMonth: pre.currentDate.currMonth + 1,
        },
      }));
    }
  };
  const handlerPrevMonth = (): void => {
    const {
      currentDate: { currMonth, currYear },
    } = state;
    if (currMonth - 1 === -1) {
      setState((pre) => ({
        ...pre,
        currentDate: {
          ...pre.currentDate,
          currMonth: 11,
          currYear: currYear - 1,
        },
      }));
    } else {
      setState((pre) => ({
        ...pre,
        currentDate: {
          ...pre.currentDate,
          currMonth: pre.currentDate.currMonth - 1,
        },
      }));
    }
  };
  const handleNextYear = (): void => {
    setState((pre) => ({
      ...pre,
      currentDate: {
        ...pre.currentDate,
        currYear: pre.currentDate.currYear + 1,
      },
    }));
  };
  const handlePrevYear = (): void => {
    setState((pre) => ({
      ...pre,
      currentDate: {
        ...pre.currentDate,
        currYear: pre.currentDate.currYear - 1,
      },
    }));
  };

  const selectDate = (date: string) => {
    const selectedDate = new Date(date);
    const selMonth = selectedDate.getMonth();
    const selYear = selectedDate.getFullYear();
    if (rangePicker) {
      setState((pre) => {
        if (typeof pre.selectedDate !== "string") {
          const isStartEnd = pre.selectedDate && pre.selectedDate.length === 2;
          const isStart = pre.selectedDate && pre.selectedDate.length > 0;
          return {
            ...pre,
            selectedDate: isStartEnd
              ? [date]
              : isStart
              ? [...(pre.selectedDate as string[]), date]
              : [date],
            currentDate: {
              currYear: selYear,
              currMonth: selMonth,
            },
          };
        } else {
          return { ...pre };
        }
      });
    }

    if (!rangePicker) {
      setState((pre) => {
        return {
          ...pre,
          selectedDate: date,
          currentDate: {
            currYear: selYear,
            currMonth: selMonth,
          },
        };
      });
    }
  };

  const handleClearDate = (e:any) => {
    e.stopPropagation()
    setState((pre) => ({ ...pre, selectedDate: null }));
  };

  const handleToggleCalendarVisibility = () => {
    setState((pre) => ({
      ...pre,
      calendarVisibility: !pre.calendarVisibility,
    }));
  };
  const {
    currentDate,
    days,
    today,
    selectedDate,
    calendarVisibility,
  } = state;

  return (
    <div tabIndex={0}  onBlur={(e:any) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setState((pre) => ({...pre,calendarVisibility:false }))
      }
    }} className={styles.date}>
      <div
        className={classNames(styles.dateField,{range: rangePicker})}
        onClick={handleToggleCalendarVisibility}
      >
        {!rangePicker && (
          <Typography
            size="small"
            tag="span"
            color={selectedDate ? "black" : "fume"}
          >
            {selectedDate ?  moment(new Date(selectedDate as string)).format('DD. MM. YYYY') : "Datum auswählen"}
          </Typography>
        )}
        {rangePicker && (
          <>
            <Typography
              size="small"
              tag="span"
              color={selectedDate?.[0] ? "black" : "fume"}
            >
              { selectedDate?.[0] ? moment(new Date(selectedDate?.[0] as string)).format('DD. MM. YYYY') : "Start Datum"}
            </Typography>
            <img src={FromToArrow} alt='from-to'/>
            <Typography
              size="small"
              tag="span"
              color={selectedDate?.[1] ? "black" : "fume"}
            >
              {selectedDate?.[1] ? moment(new Date(selectedDate?.[1] as string)).format('DD. MM. YYYY') : "End Datum"}
            </Typography>
          </>
        )}
        {selectedDate && (
          <span className={styles.dateFieldClear} onClick={handleClearDate}>
            +
          </span>
        )}
      </div>
      <div
        className={classNames(styles.dateWindow, 'theme-block', { show: calendarVisibility })}
      >
        <div className={styles.dateWindowHeader}>
          <div className={styles.dateWindowHeaderPrevBtns}>
            <button
              className={styles.dateWindowHeaderPrevBtnsPrevYear}
              onClick={handlePrevYear}
            >
              <img src={DoubleLeftArrow} alt='prev-year'/>
            </button>
            <button
              className={styles.dateWindowHeaderPrevBtnsPrevMonth}
              onClick={handlerPrevMonth}
            >
              <img src={LeftArrow} alt='prev-month'/>
            </button>
          </div>
          <Typography bold size="small">
            {`${months[currentDate.currMonth]} ${currentDate.currYear}`}
          </Typography>
          <div className={styles.dateWindowHeaderNextBtns}>
            <button
              className={styles.dateWindowHeaderNextBtnsNextMonth}
              onClick={handlerNextMonth}
            >
              <img src={RightArrow} alt='next-month'/>
            </button>
            <button
              className={styles.dateWindowHeaderNextBtnsNextYear}
              onClick={handleNextYear}
            >
              <img src={DoubleRightArrow} alt='next-year'/>
            </button>
          </div>
        </div>
        <div className={styles.dateWindowBody}>
          <ul className={styles.dateWindowBodyWeeks}>
            {weeks.map((w) => (
              <li className={styles.dateWindowBodyWeeksWeek} key={w}>
                {w.slice(0,2)}
              </li>
            ))}
          </ul>
          <ul className={styles.dateWindowBodyDays}>
            {days.map((d, i) => {
              const disabled = (rangePicker && selectedDate?.[0] as string > d.fullDate);
              const start = (rangePicker && selectedDate?.[0] as string === d.fullDate);
              const end = (rangePicker && selectedDate?.[1] as string === d.fullDate);
              const inRange = (rangePicker && (selectedDate?.[0] as string) < d.fullDate &&
                  selectedDate?.[1] as string > d.fullDate)
              return (
                <li
                  key={i + 1}
                  className={classNames(styles.dateWindowBodyDaysDay, {
                    inActive: d.inActice,
                    active: d.fullDate === selectedDate,
                    today: d.fullDate === today,
                    disabled,
                    start,
                    end,
                    inRange,
                  })}
                  onClick={() => !disabled && selectDate(d.fullDate)}
                >
                  {d.day}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(DatePicker);
