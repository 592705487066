import React, { FC, ReactNode } from "react";
import BoxLink from "../../components/BoxLinks/BoxLinks";
import styles from "./HomePageLayout.module.scss";
import { stateHomepage } from "./stateHomePage";
type HomePageLayoutProps = {
  children: ReactNode | ReactNode[];
};

const HomePageLayout: FC<HomePageLayoutProps> = ({ children }) => {
  return (
    <div className={styles.home}>
      <BoxLink items={stateHomepage.boxLinks} hide={!!children}  />
      {children}
    </div>
  );
};

export default HomePageLayout;
