
import React, { FC, ReactNode } from 'react';
import BoxLink from '../components/BoxLinks/BoxLinks';
import { IPageConfig } from '../models/page';


type AfternoonCareHomePageLayoutProps = {
  children?: ReactNode | ReactNode[];
  items: IPageConfig[];
};

export const AfternoonCareHomePageLayout:FC<AfternoonCareHomePageLayoutProps> = ({children, items}) => {
  return (
    <div>
      <BoxLink items={items} hide={!!children}  />
      {children}
    </div>
  );
};
