import React, { FC } from "react";
import Typography from "../UI/Typography/Typography";
import styles from "./ContentPage.module.scss";
import classNames from "classnames";
import Slider from "../Slider/Slider";
import moment from "moment";
import { Link } from "react-router-dom";
import Img from "../UI/Img/Img";
import { EFileVersion, IFile, INews, NewsService } from "../../service/news";
import PdfLink from "../PdfLink/PrfLink";
import { isPdfFile } from "../../utils/isPdfFile";

type ContentPageProps = {
  className?: string;
  data: INews;
  link?: string;
  listItem?: boolean;
  small?: boolean;
};
const ContentPage: FC<ContentPageProps> = ({
  data,
  listItem,
  className,
  link,
  small,
}) => {
  const { title, content, abstract, from, place, to, attachedFiles } = data;
  const pdfs:IFile[] = attachedFiles.filter((file) => isPdfFile(file.fileParam.original) && file) 
  let images = attachedFiles.filter((file) => !isPdfFile(file.fileParam.original) && file)
  const imagesInText = content.matchAll(/src="\/api\/v[12]\/uploads\?file=uploads\/thumb\/(.*?)_[a-z]{2}"/gm)
  if (!small) {
    for (const match of Array.from(imagesInText)) {
      images = images.filter(f => !f.fileName.includes(match[1]))
    }
  }
  const slider = <>
    {!!images.length  && (
        <div className={styles.contentBodyImgContent}>
          {images.length > 1 ? (
              <Slider
                  buttonNavigation
                  dotsNavigation
                  dotsNavigationPosition="bottom"
              >
                {images.map((file) => !isPdfFile(file.fileParam.original) && (<Img
                    screen
                    key={`IMG-${file.fileChksum}`}
                    src={NewsService.getFileUrl(file, EFileVersion.sm)}
                    alt={title}
                />))}
              </Slider>
          ) : (
              images.length === 1 && (
                  <Img
                      screen
                      src={NewsService.getFileUrl(
                          images[0],
                          EFileVersion.sm
                      )}
                      alt={title}
                  />
              )
          )}
        </div>
    )}
  </>

  return (
    <div className={classNames(styles.content, className, 'theme-block', { listItem })}>
      <div className={classNames(styles.contentBody, small ? styles.small : '' )}>
        {small && slider}
        <div className={styles.contentBodyText}>
          <Typography uppercase bold size="large" align="center">
            {title}
          </Typography>
          {place && (
            <div>
              <Typography tag="span">ORT:&nbsp;</Typography>
              <Typography bold color="fume" tag="span">
                {place}
              </Typography>
            </div>
          )}
          {from && (
            <div>
              <Typography tag="span" size="large">Von:&nbsp;</Typography>
              <Typography bold color="fume" size="large" tag="span">
                {moment(from).format("DD. MM. YYYY")}
              </Typography>
            </div>
          )}
          {to && (
            <div>
              <Typography tag="span" size="large">Bis:&nbsp;</Typography>
              <Typography bold color="fume" size="large" tag="span">
                {moment(to).format("DD. MM. YYYY")}
              </Typography>
            </div>
          )}
          {small
            ? abstract && (
                <div className={styles.abstractText}>
                  <div dangerouslySetInnerHTML={{ __html: abstract }} />
                </div>
              )
            : content && (
                <div  className={styles.contentText}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              )}
          {link && (
            <div>
              <Link to={link}>Weiterlesen</Link>
            </div>
          )}
        </div>
        {!small && pdfs.map((file) => (
          <PdfLink key={file?.fileName} pdfUrl={NewsService.getFileUrl(file,EFileVersion.original)} linkText={file?.fileParam.original}/>
        ))}
        {!small && slider}
      </div>
    </div>
  );
};

export default ContentPage;
