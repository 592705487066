import classNames from "classnames";
import React, {FC,  useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Img from "../../../components/UI/Img/Img";

import styles from "./BoxImageNews.module.scss";
import {EFileVersion, INews, NewsService} from "../../../service/news";
import {Config} from "../../../config/config";
import Loader from "../../../components/UI/Loader/Loader";
import Button from "../../../components/UI/Button/Button";

type BoxImageNewsProps = {
  categories: string[];
  flat?: boolean;
};

const BoxImageNews: FC<BoxImageNewsProps> = ({categories, flat}) => {
  const navigate = useNavigate();
  const [news, setNews] = useState<INews[][]>([]);
  const [loading, setLoading] = useState(true);
  const fetchNews = useCallback(async () => {
    setLoading(true);
    const fetchedNews = await NewsService.get(Config.org, 4, 0, categories);
    const slicedNews = fetchedNews.slice(0, 4);

    const firstRow = slicedNews.slice(0, 2);
    const secondRow = slicedNews.slice(2, 4);

    setNews([firstRow, secondRow]);
    setLoading(false);
  }, [categories]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const renderNews = (news: INews[]) => {
    return (
      <div className={styles.boxRowsRow}>
        {news.map((n, index) => (
          <div key={`NEWS-ITEM-${n.id}`} className={styles.boxRowsRowItem}>
            <Img
              src={n?.attachedFiles?.length > 0 ? NewsService.getFileUrl(n.attachedFiles[0], EFileVersion.md) : ''}
              alt={n?.title}
              link={`/news/${n.id}`}
              description={n.title}
              rounded
              screen
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={classNames(styles.box, {flat})}>
      {loading ? (
        <div className={styles.loading}>
          <Loader />
        </div>
      ) : (
        <>
         <div className={styles.boxRows}>
            {news.map((newsRow, index) => (
              <React.Fragment key={`NEWS-ROW-${index}`}>
                {renderNews(newsRow)}
              </React.Fragment>
            ))}
         </div>
          <Button size="large"  variant="primary" onClick={() => navigate('/news-list')}>Alle Neuigkeiten</Button>
        </>
      )}
    </div>
  );
};

export default BoxImageNews
