import React, {FC, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Typography from "../UI/Typography/Typography";
import styles from "./BoxListNews.module.scss";
import {INews, NewsService} from "../../service/news";
import {Config} from "../../config/config";
import classNames from "classnames";

interface IBoxListNewsProps {
    categories: string[];
    offset?: number;
    limit?: number;
    title: string;
}

const BoxListNews: FC<IBoxListNewsProps> = ({categories, offset, limit, title}) => {
    const [news, setNews] = useState<INews[]>([])
    const refresh = useCallback(async () => {
        setNews(await NewsService.get(Config.org, limit, offset, categories))
        // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
    }, [window.location.pathname]);
    useEffect(() => {
        refresh();
    }, [refresh]);

    return (
        <div className={classNames(styles.contentBoxNews, 'theme-block bordered')} key={title}>
            <Typography
                className={styles.contentBoxNewsTitle}
                uppercase
                align="start"
                tag="h4"
            >
                {title}
            </Typography>
            <ul className={styles.contentBoxNewsList}>
                {news.map((item) => (
                    <Link
                        className={styles.contentBoxNewsListItem}
                        key={item.id}
                        to={`/news/${item.id}`}
                    >
                        <Typography
                            className={styles.contentBoxNewsListItemText}
                            dblock
                            tag="span"
                            color="fume"
                            align="start"
                        >
                            {item.title}
                        </Typography>
                    </Link>
                ))}
            </ul>
        </div>

    );
};

export default BoxListNews;
