import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import BoxLink from "../../components/BoxLinks/BoxLinks";
import DatePicker from "../../components/UI/DatePicker/DatePicker";
import Typography from "../../components/UI/Typography/Typography";
import { stateHomepage } from "../HomePage/stateHomePage";
import Event from "./Event/Event";
import styles from "./EventsPage.module.scss";
import { IEventPageState } from "./EventsPageModels";
import {INews, NewsService} from "../../service/news";
import {Config} from "../../config/config";

type EventsPageProps = {
  children?: ReactNode | ReactNode[]
}

const EventsPage:FC<EventsPageProps> = ({children}) => {
  const [state, setState] = useState<IEventPageState>({
    events: [],
    filter: null,
    loading:true
  });

  const refresh = useCallback(async () => {
    try {
      setState((pre) => ({ ...pre,  loading: true, error:null, news:null }))
      const news = await NewsService.get(Config.org, 100, 0, ['termine']);
      const sorted = news.sort((a: INews, b: INews) => {
        return new Date(a.from) > new Date(b.from) ? 1 : -1;
      });
      setState((pre) => ({ ...pre, events:sorted, loading: false }))
    } catch (e) {
      setState((pre) => ({ ...pre, events: [], error: e, loading: false }))
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const setFilter = (evt: string[] | string | null) => {
    if (Array.isArray(evt) && evt.length === 2) {
      setState((pre) => ({ ...pre, filter: evt as string[] }));
    }
    if (evt === null) {
      setState((pre) => ({ ...pre, filter: evt }));
    }
  };
  const { events, filter } = state;
  const filteredEvents = events.filter((evt) => {
    if (filter) {
      return (new Date(filter[0]) <= new Date(evt.from && evt.from.slice(0,10))  && (new Date(filter[1]) >= new Date(evt.from && evt.from.slice(0,10)) ))
    } else {
      return true;
    }
  });
  const halfTotalEvents = Math.ceil(filteredEvents.length / 2);
  const leftSideEvents = filteredEvents.slice(0, halfTotalEvents);
  const rightSideEvents = filteredEvents.slice(halfTotalEvents);
  return (
    <div className={styles.events}>
      <BoxLink items={stateHomepage.boxLinks} hide />
      {children}
      <div className={styles.eventsTitle}>
        <Typography tag="span" uppercase size="large">
          AKTUELLE
        </Typography>
        <Typography tag="span" uppercase bold size="large">
          &nbsp;
          Veranstaltungen
        </Typography>
      </div>
      <DatePicker rangePicker onChange={(evt) => setFilter(evt)} />

      {Boolean(events.length) && (
        <div className={styles.eventsContent}>
          <div className={styles.eventsContentList}>
            {leftSideEvents.map((evt, i) => (
              <Event className={styles.eventsContentListEvent} key={evt.title} data={evt}  />
            ))}
          </div>
          {Boolean(rightSideEvents.length) && (
            <div className={styles.eventsContentList}>
              {rightSideEvents.map((evt) => (
                <Event className={styles.eventsContentListEvent} key={evt.title} data={evt} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventsPage;
