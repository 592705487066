import React from "react";
import {CityPageLayout} from "../../CityPageLayout";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import PeoplePage from "../../../PeoplePage/PeoplePage";

const MayorPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>
    {children}
    <PeoplePage group={'STADTRAT'} />
  </>
};

export default withGenericArticle(CityPageLayout, MayorPage);
