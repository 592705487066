import React, { useState } from "react";
import styles from "./SearchField.module.scss";
import SearchIcon from "../../../images/search.png";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

type SearchFieldState = {
  searchText: string;
  searchTextVisibility: boolean;
};

const SearchField = () => {
  const [state, setState] = useState<SearchFieldState>({
    searchText: "",
    searchTextVisibility: false,
  });
  const navigate = useNavigate();

  const handleSearch = () => {
    if (!state.searchTextVisibility) {
      setState((pre) => ({ ...pre, searchTextVisibility: true }));
    }

    if (state.searchText.length > 3) {
      navigate(`/search?search=${state.searchText}`);
      setState((pre) => ({ ...pre, searchText: "" }));
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      handleSearch();
    }
  };

  return (
    <div
      tabIndex={0}
      onBlur={(e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setState((pre) => ({ searchText: "", searchTextVisibility: false }));
        }
      }}
      className={classNames(styles.search, {
        show: state.searchTextVisibility,
      })}
    >
      <input
        className={classNames(styles.searchField, 'theme-block bordered')}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          setState((pre) => ({ ...pre, searchText: evt.target.value }))
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleEnter(e)}
        onSubmit={() => console.log("enter")}
        value={state.searchText}
        type="text"
      />
      <button onClick={handleSearch} className={classNames(styles.searchBtn, 'theme-block bordered')}>
        <img src={SearchIcon} alt="search" />
      </button>
    </div>
  );
};

export default SearchField;
