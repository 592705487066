import React from 'react';
import styles from './DigitalPage.module.scss'
import DigitalPageLayout from './DigitalPageLayout';
import {IPageConfig} from "../../models/page";
import {IGenericArticleProps, withGenericArticle} from "../Generic/withGenericArticle";

const DigitalPage: React.FC<IGenericArticleProps> = ({children}) => {
  return (
      <>{children}</>

  );
};

export default withGenericArticle(DigitalPageLayout, DigitalPage);

/*<div className={styles.digital}>
      <DigitalPageLayout items={items}/>
    </div>*/