import React, {useCallback, useEffect, useState} from 'react';
import Person from './Person/Person';
import styles from './PeoplePage.module.scss'
import {Config} from "../../config/config";
import {IMember, MemberService, TPeopleType} from "../../service/member";

interface IPeoplePageProps {
  group?: TPeopleType
}

interface IPeoplePageState {
  loading: boolean;
  members: IMember[] | null;
}
const PeoplePage: React.FC<IPeoplePageProps> = ({group}) => {
  const [state, setState] = useState<IPeoplePageState>({
    loading: false,
    members: null,
  });
  const refresh = useCallback(async () => {
    setState((pre) => ({ ...pre, loading: true }));

    const members = await MemberService.get(Config.org, group || 'STADTRAT')

    setState((pre) => ({ ...pre, members, loading: false }));

    // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    refresh();
  }, [refresh]);



  return (
    <div className={styles.people}>
      
      {state?.members?.map((per) => (
        <div key={per.uuid} className={styles.peopleItem}><Person person={per}/></div>
      ))}
    </div>
  );
};

export default PeoplePage;