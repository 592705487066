import React from 'react';
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import {CityPageLayout} from "../../CityPageLayout";
import PeoplePage from "../../../PeoplePage/PeoplePage";
import Typography from "../../../../components/UI/Typography/Typography";

const CommitteesPage: React.FC<IGenericArticleProps> = ({children}) => {
 return <>
  {children}
  <br/>
  <Typography size="large" bold>Bau & Infrastruktur</Typography>
  <PeoplePage group={'BAU'} />
  <br/>
  <Typography size="large" bold>Kultur & Soziales</Typography>
  <PeoplePage group={'KULTUR'} />
  <br/>
  <Typography size="large" bold>Sport & Bildung</Typography>
  <PeoplePage group={'SPORT'} />
  <br/>
  <Typography size="large" bold>Standortmanagement & Gemeindekooperationen</Typography>
  <PeoplePage group={'STANDORT'} />
  <br/>
  <Typography size="large" bold>Umwelt & Nachhaltigkeit</Typography>
  <PeoplePage group={'UMWELT'} />
  <br/>
  <Typography size="large" bold>Prüfungsausschuss</Typography>
  <PeoplePage group={'PRUEFUNG'} />
 </>
};



export default withGenericArticle(CityPageLayout, CommitteesPage);