import React from "react";
import DigitalPageLayout from "../../DigitalPageLayout";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";


const CitiesAppPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(DigitalPageLayout, CitiesAppPage);
