
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { economicYardState } from "../states/economicYard";
import EconomicYardHomePage from "./pages/EconomicYardHomePage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Layout from "../layouts/MainLayout/Layout";
import { economicYardHomePageState } from "./pages/economicYardHomePageState";
import { generateRoutes } from "../utils/generateRoutes";


function AppEconomicYard() {
  const root = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/"  element={<Layout layoutProps={economicYardState} />}>
          <Route index path="/"  element={<EconomicYardHomePage />} />
          {/* Child pages of the main page */}
            {generateRoutes(economicYardHomePageState.boxLinks).map((page) => (
              <Route key={page.path} path={page.path} element={page.component} />
            ))}
          {/* Child pages of the main page */}

        </Route>

      </>
    )
  );
  return (
    <div >
      <RouterProvider router={root} />
    </div>
  );
}

export default AppEconomicYard;
//<Route path="*" element={ <ErrorPage/> }/>