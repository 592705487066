export class Config {
  public static get org() {
    switch (process.env.REACT_APP_ORGANIZATION) {
      case "oberwart":
        return 1;
      case "pts":
        return 10;
      case "school":
        return 11;
      case "elementarySchool":
        return 13;
      case "generalSchool":
        return 14;
      case "economicYard":
        return 15;
      case "roa":
        return 16;
      case "creche":
        return 17;
      case "afternoonCare":
        return 18;
      case "kindergarten":
        return 19;
      default:
        return 1;
    }
  }
}
