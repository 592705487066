import classNames from 'classnames';
import React, { FC } from 'react';
import { Location, NavLink, useLocation } from 'react-router-dom';
import { IBoxLink } from '../../models/boxLink';
import { Theme } from '../../models/layout';
import Container from '../Container/Container';
import Icon from '../UI/Icon/Icon';
import Typography from '../UI/Typography/Typography';
import styles from './BoxLinks.module.scss'


type BoxLinksProps = {
  items: IBoxLink[];
  hide?:boolean;
}


const BoxLink:FC<BoxLinksProps> = ({items, hide}) => {
  const location: Location = useLocation();
  const pathname: string = decodeURIComponent(location.pathname)
  const doLess = !!items?.find((item) => item.link === pathname || pathname === '/events')
  return (
    <Container className={classNames(styles.container,  {hide, doLess})}>
      {items?.filter(x => x.img.length > 0).map((item) => ( <NavLink key={item.link} to={item.link} className={classNames(styles.containerBox, 'theme-block bordered org-theme-box-link')}>
        <Icon src={item.img} className={styles.containerBoxImg}/>
        {!doLess && (
          <Typography className={styles.containerBoxText} color='black' align='center' tag='span' size='smaller'>{item.label}</Typography>
        )} 
      </NavLink>))}
    </Container>
  );
};

export default BoxLink;