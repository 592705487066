import React, {useCallback, useEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";
import ContentPage from "../../components/ContentPage/ContentPage";
import HeaderTop from "../../components/Header/HeaderTop/HeaderTop";
import {oberwartLayoutState} from "../../states/oberwar";
import styles from "./SearchPage.module.scss";
import {INews, NewsService} from "../../service/news";
import {Config} from "../../config/config";
import Typography from "../../components/UI/Typography/Typography";

const SearchPage = () => {

    const [params] = useSearchParams();
    const searchValue = params.get('search')

    const [news, setNews] = useState<INews[]>([])
    const refresh = useCallback(async () => {
        setNews( await NewsService.find(Config.org, 20, 0, searchValue || ''));
    }, [searchValue]);


    useEffect(() => {
        refresh();
    }, [refresh]);
   

    return (
        <div className={styles.search}>
            <HeaderTop bordered logo={oberwartLayoutState.header.logo} topName={oberwartLayoutState.header.topName}/>
            {news.length > 0 && news.map((n, i) => (
                <ContentPage
                    small
                    className={styles.searchContent}
                    key={`DATA-${i + 1}`}
                    data={n}
                    link={`/news/${n.id}`}
                />
            ))}

            {news.length < 1 && (
                <Typography bold tag="h3" align="center">es wurde nichts gefunden</Typography>
            )}
        </div>
    );
};

export default SearchPage;
