import AccommodationPage from "./LifeChildPage/AccommodationPage/AccommodationPage";
import AssociationsInstitutionsPage from "./LifeChildPage/AssociationsInstitutionsPage/AssociationsInstitutionsPage";
import BusinessPage from "./LifeChildPage/BusinessPage/BusinessPage";
import BusinessSettlementsPage from "./LifeChildPage/BusinessSettlementsPage/BusinessSettlementsPage";
import CulturePage from "./LifeChildPage/CulturePage/CulturePage";
import ExhibitionCenterPage from "./LifeChildPage/ExhibitionCenter/ExhibitionCenterPage";
import GastronomyPage from "./LifeChildPage/GastronomyPage/GastronomyPage";
import HallOccupancyPage from "./LifeChildPage/HallOccupancyPage/HallOccupancyPage";
import LeisureTourism from "./LifeChildPage/LeisureTourism/LeisureTourism";
import LibraryPage from "./LifeChildPage/LibraryPage/LibraryPage";
import MarketsPage from "./LifeChildPage/MarketsPage/MarketsPage";
import PlaygroundsPage from "./LifeChildPage/PlaygroundsPage/PlaygroundsPage";
import PromotionsPage from "./LifeChildPage/PromotionsPage/PromotionsPage";
import RunningWCTPage from "./LifeChildPage/RunningWCTPage/RunningWCTPage";
import SeniorClubPage from "./LifeChildPage/SeniorClubPage/SeniorClubPage";
import ShoppingCityPage from "./LifeChildPage/ShoppingCityPage/ShoppingCityPage";
import ShoppingPage from "./LifeChildPage/ShoppingPage/ShoppingPage";
import SouthernBurgenlandTourismPage from "./LifeChildPage/SouthernBurgenlandTourismPage/SouthernBurgenlandTourismPage";
import SportsPage from "./LifeChildPage/Sports/SportsPage";
import SportsFacilitiesPage from "./LifeChildPage/SportsFacilitiesPage/SportsFacilities";
import {IPageConfig} from "../../models/page";


export const lifePageState: IPageConfig[] = [
  {
    uuid: "26E57241-04EA-443A-96F7-D7566B6372FA",
    link: "/leben",
    label: "Leben",
    img: "",
    content: "",
    component: (items, data, parent) => <SportsPage parent={parent} items={items} data={data}/>,
    children: [

      {
        uuid: "9FEDA439-3959-4319-8941-4C33032C8C31",
        link: "/leben/sport",
        label: "Sport",
        img: "/icons/sports.svg",
        content: "",
        component: (items, data, parent) => <SportsPage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "0FC9F2AD-28AA-40C7-9EF4-61D778CEEA65",
            link: "/leben/sport/sportanlagen",
            label: "Sportanlagen",
            img: "/icons/sports-facilities.svg",
            content: "",
            component: (items, data, parent) => <SportsFacilitiesPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "6F2A604F-9226-48E3-9E59-2FF23134FF92",
            link: "/leben/sport/hallenbelegungsplan",
            label: "Hallenbele- gungsplan",
            img: "/icons/hall-occupancy-plan.svg",
            content: "",
            component: (items, data, parent) => <HallOccupancyPage parent={parent} items={items} data={data}/>,
          },
        ],
      },
      {
        uuid: "08B4A300-F21E-4DB5-A8CF-57B46013E1F3",
        link: "/leben/kultur",
        label: "Kultur",
        img: "/icons/culture.svg",
        content: "",
        component: (items, data, parent) => <CulturePage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "ED856152-AAC6-4760-B614-E21435C64A0F",
        link: "/leben/wirtschaft",
        label: "Wirtschaft",
        img: "/icons/business.svg",
        content: "",
        component: (items, data, parent) => <BusinessPage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "B5811DF3-2936-4F71-92BA-8C91BA479C6F",
            link: "/leben/wirtschaft/messezentrum",
            label: "Messezentrum",
            img: "/icons/exhibition-center.svg",
            content: "",
            component: (items, data, parent) => <ExhibitionCenterPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "DA28678B-73DE-41E2-B046-C24C590503C5",
            link: "/leben/wirtschaft/förderungen",
            label: "Förderungen",
            img: "/icons/promotions.svg",
            content: "",
            component: (items, data, parent) => <PromotionsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "CF2C5D31-1C0D-4272-8745-7BA0C4324228",
            link: "/leben/wirtschaft/betriebsansiedelungen",
            label: "Betriebs- ansiedelungen",
            img: "/icons/business-settlements.svg",
            content: "",
            component: (items, data, parent) => <BusinessSettlementsPage parent={parent} items={items} data={data}/>,
          },
        ],
      },
      {
        uuid: "1C41278E-268A-4AAA-93A7-5312C74835AB",
        link: "/leben/vereine-institutionen",
        label: "Vereine & Institutionen",
        img: "/icons/associations-institutions.svg",
        content: "",
        component: (items, data, parent) => <AssociationsInstitutionsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "76AC5E0B-F6EB-44D0-96E1-76C03A32CFD4",
        link: "/leben/bücherei",
        label: "Bücherei",
        img: "/icons/library.svg",
        content: "",
        component: (items, data, parent) => <LibraryPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "78A362C6-E90F-4105-82CC-3D7434D12405",
        link: "/leben/freizeit-tourismus",
        label: "Freizeit & Tourismus",
        img: "/icons/leisure-tourism.svg",
        content: "",
        component: (items, data, parent) => <LeisureTourism parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "1E2A98C3-0E48-4B43-B255-8CF749239499",
            link: "/leben/freizeit-tourismus/spielplätze",
            label: "Spielplätze",
            img: "/icons/playgrounds.svg",
            content: "",
            component: (items, data, parent) => <PlaygroundsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "B74506DA-F02A-41FB-A6C4-A5EB49E8503F",
            link: "/leben/freizeit-tourismus/seniorenclub",
            label: "Seniorenclub",
            img: "/icons/senior-club.svg",
            content: "",
            component: (items, data, parent) => <SeniorClubPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "8A126041-0813-4E6E-A5FE-CF1225011FC7",
            link: "/leben/freizeit-tourismus/lauf-walking-radwege",
            label: "Laufen Walken Radfahren",
            img: "/icons/running-WCT.svg",
            content: "",
            component: (items, data, parent) => <RunningWCTPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "5199419F-E4DF-4164-AD7C-D985BBA0058B",
            link: "/leben/freizeit-tourismus/südburgenland-tourismus",
            label: "Südburgenland Tourismus",
            img: "/icons/southern-burgenland-tourism.svg",
            content: "",
            component: (items, data, parent) => <SouthernBurgenlandTourismPage parent={parent} items={items}
                                                                               data={data}/>,
          },
          {
            uuid: "9E6DBE81-836B-4AE9-9386-53FD9C268085",
            link: "/leben/freizeit-tourismus/beherbergung",
            label: "Beherbergung",
            img: "/icons/accommodation.svg",
            content: "",
            component: (items, data, parent) => <AccommodationPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "3E2545AD-6458-47C8-A98F-ABF1CAFB2A3C",
            link: "/leben/freizeit-tourismus/gastronomie",
            label: "Gastronomie",
            img: "/icons/gastronomy.svg",
            content: "",
            component: (items, data, parent) => <GastronomyPage parent={parent} items={items} data={data}/>,
          },
        ],
      },
      {
        uuid: "BA5C0B7E-69E1-4C84-A880-9AF603FE1067",
        link: "/leben/einkaufen",
        label: "Einkaufen",
        img: "/icons/shopping.svg",
        content: "",
        component: (items, data, parent) => <ShoppingPage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "8F88DBD5-2F18-4A91-A78B-BF954D4E6E54",
            link: "/leben/einkaufen/einkaufsstadt",
            label: "Einkaufsstadt",
            img: "/icons/shopping-city.svg",
            content: "",
            component: (items, data, parent) => <ShoppingCityPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "B8D55ED8-0DBD-4E1D-B219-A9E640876A1B",
            link: "/leben/einkaufen/märkte",
            label: "Märkte",
            img: "/icons/markets.svg",
            content: "",
            component: (items, data, parent) => <MarketsPage parent={parent} items={items} data={data}/>,
          },
        ],
      },
    ]
  }
];
