import {IPageConfig} from "../models/page";


type RouteArr = {
  path: string;
  component: JSX.Element
}

export type ITargetTranslation = {[key:string]: string}
export interface ITargetSection {
  content: string[] | string;
  translations?: { [key: string]: ITargetTranslation };
  theme?: string;
  //view: ETargetView | string;
  role?: number;
}

export interface ITargetPage {
  title: ITargetTranslation;
  url?: string;
  header?: ITargetSection;
  page?: ITargetSection;
  sections?: ITargetSection[];
  role?: number;
}

export interface ITargetMenu {
  sort?: number;
  title: ITargetTranslation;
  icon?: string;
  page?: ITargetPage;
  children?: ITargetMenu[];
}

export function generateRoutes(arr:IPageConfig[], p?:IPageConfig): RouteArr[] {
  const res: RouteArr[] = []
  arr.forEach((el) => {
    res.push({path: el.link, component: el.component(arr, el, p)})
    if(el.children){
      return  res.push(...generateRoutes(el.children, el))
    }
  })

  return res.flat(Infinity)
}


export function generateMenuPage(title: string, uuid: string, children?: any[]) {
  return {
    title: {
      _default: title,
    },
    page: {
      title: {
        _default: title,
      },
      page: {
        content: uuid,
      },
    },
    children
  }
}
export function generateRoutesMenu(arr:IPageConfig[]): ITargetMenu[] {
  const res: ITargetMenu[] = []
  arr.forEach((el) => {
    let children = undefined;

    if(el.children){
      children = generateRoutesMenu(el.children)
    }
    res.push(generateMenuPage(el.label, el.uuid, children));
  })

  return res;//.flat(Infinity)
}