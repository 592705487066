import React, {FC, ReactNode} from 'react';
import BoxLink from '../../components/BoxLinks/BoxLinks';
import {IPageConfig} from "../../models/page";

type LifePageLayoutProps = {
    items: IPageConfig[];
    children?: ReactNode | ReactNode[]
}

const LifePageLayout: FC<LifePageLayoutProps> = ({children, items}) => {
    return (
        <div>
            <BoxLink items={items} hide={!!children}/>
            {children}
        </div>
    );
};

export default LifePageLayout;