

import React from 'react';
import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { KindergartenHomePageLayout } from '../KindergartenHomePageLayout';

const KindergartenContactOpeningTimesPage: React.FC<IGenericArticleProps> = ({children}) => {
  return children
};

export default withGenericArticle(KindergartenHomePageLayout, KindergartenContactOpeningTimesPage); 