import React from 'react';

type Props = {
  pdfUrl: string;
  linkText: string;
};

function PdfLink({ pdfUrl, linkText }: Props) {
  console.log('pdfUrl',pdfUrl)
  return (
    <div>
      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
        {linkText}
      </a>
    </div>
  );
}

export default PdfLink;
