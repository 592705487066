import React from 'react';
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import {CityPageLayout} from "../../CityPageLayout";
import PeoplePage from "../../../PeoplePage/PeoplePage";

const CouncilPage: React.FC<IGenericArticleProps> = ({children}) => {
 return <>
  {children}
  <PeoplePage group={'GEMEINDERAT'} />
 </>
};

export default withGenericArticle(CityPageLayout, CouncilPage);