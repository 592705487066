import React, { useCallback, useEffect, useState} from "react";
import Loader from "../../components/UI/Loader/Loader";
import ContentPage from "../../components/ContentPage/ContentPage";
import {INews, NewsService} from "../../service/news";
import {Config} from "../../config/config";
import BoxLink from "../../components/BoxLinks/BoxLinks";
import Typography from "../../components/UI/Typography/Typography";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {IPageConfig} from "../../models/page";
import {NavigateUp} from "../../components/NavigateUp/NavigateUp";

export interface IGenericArticleProps {
    children: any;
}

interface IGenericArticleState {
    loading: boolean;
    article: INews[] | null;
}
export const withGenericArticle = (
    Layout: any,
    Component: any,
) => (props: { items: IPageConfig[] , data: IPageConfig, parent?: IPageConfig }) => {
    const [state, setState] = useState<IGenericArticleState>({
        loading: false,
        article: null,
    });

    const { loading, article } = state;
    const { items, data, parent } = props;

    const refresh = useCallback(async () => {
        setState((pre) => ({ ...pre, loading: true }));

        const article = await NewsService.getArticle(Config.org, data.uuid)

        setState((pre) => ({ ...pre, article, loading: false }));

        // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);



    return <div>

        { data && (
            <>
                <NavigateUp item={parent}></NavigateUp>
                <Layout items={items}>

                    {data.children && data.children.length > 0 && (
                        <>

                            <Typography align="center" tag="h3">
                                {data.label}
                            </Typography>

                            {data.children && <BoxLink items={data.children} />}
                        </>
                    )}
                    <Component {...props}>
                        <Breadcrumbs />
                        {loading && <Loader />}
                        {article?.map(a => <ContentPage data={a} key={a.id}/>)}
                    </Component>
                </Layout>
            </>
        )}
    </div>
}
