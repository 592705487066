import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../Container/Container';
import Button from '../UI/Button/Button';
import Typography from '../UI/Typography/Typography';
import styles from './Cookie.module.scss';

type CookieType = {
  agreement: () => void
}

const Cookie:React.FC<CookieType> = ({agreement}) => {
  return (
    <div className={classNames(styles.cookie, 'theme-block bordered')}>
      <Container  className={styles.cookieContainer}>
        <div className={styles.cookieContainerText}>
          <Typography size='small' color='black'>
            Damit diese Webseite in vollem Umfang funktionsfähig ist, setzten wir sogenannte Session-Cookies ein. Diese werden nicht getrackt oder an Dritte weitergeleitet. Sie können dieses Session-Cookie jederzeit in ihrem Browser löschen. 
          </Typography>
          <Link className={classNames(styles.link, 'theme-color')} to='/datenschutz'> Unsere Datenschutzerklärung </Link>
        </div> 
        <div className={styles.cookieContainerBtn}>
          <Button onClick={agreement} variant='primary'> Ich habe verstanden </Button>
        </div> 
      </Container>
    </div>
  );
};

export default Cookie;
