import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import Loader from "../../../components/UI/Loader/Loader";
import { Config } from "../../../config/config";
import { INews, NewsService } from "../../../service/news";
import Event from "../../EventsPage/Event/Event";
import styles from "./BoxEvents.module.scss";

type BoxEventsState = {
  events: INews[];
  loading: boolean;
};

const BoxEvents = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<BoxEventsState>({
    events: [],
    loading: true,
  });

  const refresh = useCallback(async () => {
    try {
      setState((pre) => ({ ...pre, loading: true, error: null, news: null }));
      const news = await NewsService.get(Config.org, 7, 0, ["termine"]);
      const sorted = news.sort((a: INews, b: INews) => {
        return new Date(a.from) > new Date(b.from) ? 1 : -1;
      });
      setState((pre) => ({ ...pre, events: sorted, loading: false }));
    } catch (e) {
      setState((pre) => ({ ...pre, events: [], error: e, loading: false }));
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);
  const { events, loading } = state;
  return (
    <div className={styles.BoxEvents}>
      {loading && !!events.length ? (
        <Loader />
      ) : (
        <>
          <div className={classNames(styles.BoxEventsList, 'bordered')}>
            {events.map((event) => (
              <Event key={`${event.id}`} data={event} />
            ))}
          </div>
          <Button size="large" variant="primary" onClick={() => navigate('/events')}>Alle Veranstaltungen</Button>
        </>
      )}
    </div>
  );
};

export default BoxEvents;
