import React from "react";
import HomePageLayout from "../../HomePageLayout";
import {
  IGenericArticleProps,
  withGenericArticle,
} from "../../../Generic/withGenericArticle";

const ContactMunicipalPage: React.FC<IGenericArticleProps> = ({ children }) => {
  return children;
};

export default withGenericArticle(HomePageLayout, ContactMunicipalPage);
