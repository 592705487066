import AdultEducationPage from "./ServiceChildPage/AdultEducationPage/AdultEducationPage";
import AssistedLivingPage from "./ServiceChildPage/AssistedLivingPage/AssistedLivingPage";
import ConstructionDepartmentPage from "./ServiceChildPage/ConstructionDepartmentPage/ConstructionDepartmentPage";
import GarbageCalendarPage from "./ServiceChildPage/GarbageCalendarPage/GarbageCalendarPage";
import KindergartenAfterPage from "./ServiceChildPage/KindergartenAfterPage/KindergartenAfterPage";
import MyOfficialWayPage from "./ServiceChildPage/MyOfficialWayPage/MyOfficialWayPage";
import NewInOberwartPage from "./ServiceChildPage/NewInOberwartPage/NewInOberwartPage";
import ParkingTrafficPage from "./ServiceChildPage/ParkingTrafficPage/ParkingTrafficPage";
import ServiceBuildLivePage from "./ServiceChildPage/ServiceBuildLivePage/ServiceBuildLivePage";
import ServiceChildcarePage from "./ServiceChildPage/ServiceChildcarePage/ServiceChildcarePage";
import ServiceSchoolsPage from "./ServiceChildPage/ServiceSchoolsPage/ServiceSchoolsPage";
import SettlementCooperativesPage from "./ServiceChildPage/SettlementCooperativesPage/SettlementCooperativesPage";
import СollectionPointsPage from "./ServiceChildPage/СollectionPointsPage/СollectionPointsPage";
import WasteDisposalPage from "./ServiceChildPage/WasteDisposalPage/WasteDisposalPage";
import HealthSocialPage from "./ServiceChildPage/HealthSocialPage/HealthSocialPage";
import DoctorsPage from "./ServiceChildPage/DoctorsPage/DoctorsPage";
import PsychologistsPage from "./ServiceChildPage/PsychologistsPage/PsychologistsPage";
import PhysiotherapistsPage from "./ServiceChildPage/PhysiotherapistsPage/PhysiotherapistsPage";
import HealthSocialInstitutionsPage from "./ServiceChildPage/HealthSocialInstitutionsPage/HealthSocialInstitutionsPage";
import SelfHelpPage from "./ServiceChildPage/SelfHelpPage/SelfHelpPage";
import ServicePromotionsPage from "./ServiceChildPage/ServicePromotionsPage/ServicePromotionsPage";
import FeesRegulationsPage from "./ServiceChildPage/FeesRegulationsPage/FeesRegulationsPage";
import FormsPage from "./ServiceChildPage/FormsPage/FormsPage";
import CommunityNewsPage from "./ServiceChildPage/CommunityNewsPage/CommunityNewsPage";
import DistrictStatePage from "./ServiceChildPage/DistrictStatePage/DistrictStatePage";
import ServiceEventsPage from "./ServiceChildPage/ServiceEventsPage/ServiceEventsPage";
import ServiceMapPage from "./ServiceChildPage/ServiceMapPage/ServiceMapPage";
import {IPageConfig} from "../../models/page";
import ServicePage from "./ServicePage";

export const serviceState:IPageConfig[] = [
  {
    uuid: "E22345A8-080F-4024-A0AD-683B32BA9D55",
    link: "/service",
    label: "Service",
    img: "",
    content: "",
    component: (items, data, parent) => <ServicePage parent={parent} items={items} data={data}/>,
    children: [
      {
        uuid: "E7BBE10F-EF11-4E23-A83D-179A89E0CC23",
        link: "/service/mein-behördenweg",
        label: "Mein Behördenweg",
        img: "icons/my-government.svg",
        content: "",
        component: (items, data, parent) => <MyOfficialWayPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "FB79A277-0FE0-4C10-9A7F-CDC27DBD237D",
        link: "/service/neu-in-oberwart",
        label: "Neu in Oberwart",
        img: "/icons/news.svg",
        content: "",
        component: (items, data, parent) => <NewInOberwartPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "16119B20-DF63-4245-97A6-33FBE70C0410",
        link: "/service/kinderbetreuung-bildung",
        label: "Kinderbetreu- ung & Bildung",
        img: "/icons/childcare-education.svg",
        content: "",
        component: (items, data, parent) => <ServiceChildcarePage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "4131B97E-9236-49DF-AF09-23CC803320B9",
            link: "/service/kinderbetreuung-bildung/kinderkrippe-kindergarten-hort",
            label: "Kinderkrippe/Kindergarten/Hort",
            img: "/icons/creche-kindergarten-after-school-care.svg",
            content: "",
            component: (items, data, parent) => <KindergartenAfterPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "1A4DE65F-CACB-4D23-8A4F-6814CB49BCF1",
            link: "/service/kinderbetreuung-bildung/schulen",
            label: "Schulen",
            img: "/icons/schools.svg",
            content: "",
            component: (items, data, parent) => <ServiceSchoolsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "9AE9DADC-24D6-4F0C-8CDE-FF1312502809",
            link: "/service/kinderbetreuung-bildung/erwachsenenbildung",
            label: "Erwachsenenbildung",
            img: "/icons/adult-education.svg",
            content: "",
            component: (items, data, parent) => <AdultEducationPage parent={parent} items={items} data={data}/>,
          },
        ]
      },
      {
        uuid: "697B6AD4-DC87-4156-8F81-492E5ED8C3E8",
        link: "/service/bauen-wohnen",
        label: "Bauen & Wohnen",
        img: "/icons/build-live.svg",
        content: "",
        component: (items, data, parent) => <ServiceBuildLivePage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "043A512D-F151-4D3D-934E-54428B13E980",
            link: "/service/bauen-wohnen/bauabteilung",
            label: "Bauabteilung",
            img: "/icons/construction-department.svg",
            content: "",
            component: (items, data, parent) => <ConstructionDepartmentPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "F0E1CB6D-24C6-45C0-8DAE-9E38C25775A1",
            link: "/service/bauen-wohnen/betreubares-wohnen",
            label: "Betreubares Wohnen",
            img: "/icons/assisted-living.svg",
            content: "",
            component: (items, data, parent) => <AssistedLivingPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "69A0996D-EA65-4AAE-BA36-2D35A6179CAE",
            link: "/service/bauen-wohnen/siedlungsgenossenschaften",
            label: "Siedlungsgenossenschaften",
            img: "/icons/settlement-cooperatives.svg",
            content: "",
            component: (items, data, parent) => <SettlementCooperativesPage parent={parent} items={items} data={data}/>,
          },
        ]
      },
      {
        uuid: "B3B40972-E6B5-448E-8B56-047F5AF78EC2",
        link: "/service/parken-verkehr",
        label: "Parken & Verkehr",
        img: "/icons/parking.svg",
        content: "",
        component: (items, data, parent) => <ParkingTrafficPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "CCC5FBD5-75C5-4E60-B776-B9C483623899",
        link: "/service/abfallentsorgung",
        label: "Abfall- entsorgung",
        img: "/icons/waste-disposal.svg",
        content: "",
        component: (items, data, parent) => <WasteDisposalPage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "21E503DF-98A2-4D73-90A8-8A4B52F75E3F",
            link: "/service/abfallentsorgung/sammelstellen",
            label: "Sammelstellen",
            img: "/icons/collection-points.svg",
            content: "",
            component: (items, data, parent) => <СollectionPointsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "C71D54E3-39D4-4BCC-BFEF-A4B9464703B1",
            link: "/service/abfallentsorgung/müllkalender",
            label: "Müllkalender",
            img: "/icons/garbage-calendar.svg",
            content: "",
            component: (items, data, parent) => <GarbageCalendarPage parent={parent} items={items} data={data}/>,
          },
        ]
      },
      {
        uuid: "A7630B7E-A7C8-449D-AB00-349AF69F6DBD",
        link: "/service/gesundheit-soziales",
        label: "Gesundheit & Soziales",
        img: "/icons/health-social-affairs.svg",
        content: "",
        component: (items, data, parent) => <HealthSocialPage parent={parent} items={items} data={data}/>,
        children: [
          {
            uuid: "960C15FD-888E-479D-96F0-BB9BFBAB06DA",
            link: "/service/gesundheit-soziales/ärzte",
            label: "Ärzte",
            img: "/icons/doctors.svg",
            content: "",
            component: (items, data, parent) => <DoctorsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "4EF955CE-9A22-419A-98DF-E95DEFF42D1E",
            link: "/service/gesundheit-soziales/psychologen-psychotherapeuten",
            label: "Psychologen/Psychotherapeuten",
            img: "/icons/psychologists-psychotherapists.svg",
            content: "",
            component: (items, data, parent) => <PsychologistsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "1DA24B8E-71CD-47BB-9C25-6135903651C4",
            link: "/service/gesundheit-soziales/physiotherapeuten",
            label: "Physiotherapeuten",
            img: "/icons/physiotherapists.svg",
            content: "",
            component: (items, data, parent) => <PhysiotherapistsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: "BE053C8B-04B8-41AA-9D10-4046E98E4D52",
            link: "/service/gesundheit-soziales/gesundheits-und-sozialeinrichtungen",
            label: "Gesundheits und Sozialeinrichtungen",
            img: "/icons/health-social-institutions.svg",
            content: "",
            component: (items, data, parent) => <HealthSocialInstitutionsPage parent={parent} items={items}
                                                                              data={data}/>,
          },
          {
            uuid: "246FC2F9-96B8-48F3-8606-65373C8EF348",
            link: "/service/gesundheit-soziales/selbsthilfegruppen-verbände",
            label: "Selbsthilfegruppen/Verbände",
            img: "/icons/self-help-groups-associations.svg",
            content: "",
            component: (items, data, parent) => <SelfHelpPage parent={parent} items={items} data={data}/>,
          },
        ]
      },
      {
        uuid: "1D076A49-BADC-4FAC-B481-9706F514BE4A",
        link: "/service/förderungen",
        label: "Förderungen",
        img: "/icons/promotions2.svg",
        content: "",
        component: (items, data, parent) => <ServicePromotionsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "815BBF98-7095-470E-AB5B-BE36DCA3ECB3",
        link: "/service/gebühren-verordnungen",
        label: "Gebühren & Verordnungen",
        img: "/icons/fees-regulations.svg",
        content: "",
        component: (items, data, parent) => <FeesRegulationsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "E4F22E6F-1309-4E3D-8F1C-D6691D875FBD",
        link: "/service/formulare",
        label: "Formulare",
        img: "/icons/forms.svg",
        content: "",
        component: (items, data, parent) => <FormsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "CD476F19-99E4-4C32-8C9B-DAC893B65B23",
        link: "/service/gemeindenachrichten–oberwart-aktiv",
        label: "Gemeinde- nachrichten – Oberwart Aktiv",
        img: "/icons/community-news-oberwart-aktiv.svg",
        content: "",
        component: (items, data, parent) => <CommunityNewsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "A2AF3C98-63CB-4532-802F-D6421DAD6E1B",
        link: "/service/behörden-des-bezirkes-landes",
        label: "Behörden des Bezirkes & Landes",
        img: "/icons/district-state-authorities.svg",
        content: "",
        component: (items, data, parent) => <DistrictStatePage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "D69CF938-BBFE-44EC-879C-70598C293E63",
        link: "/service/veranstaltungen",
        label: "Veranstaltungen",
        img: "/icons/events.svg",
        content: "",
        component: (items, data, parent) => <ServiceEventsPage parent={parent} items={items} data={data}/>,
      },
      {
        uuid: "0CF3F3AD-0C44-4C7A-A636-B5E773CEB129",
        link: "/service/stadtplan",
        label: "Stadtplan",
        img: "/icons/map.svg",
        content: "",
        component: (items, data, parent) => <ServiceMapPage parent={parent} items={items} data={data}/>,
      },
    ]
  }
];
