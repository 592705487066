import React, { FC } from "react";
import { IFlatNews } from "../../models/flatNews";
import Img from "../UI/Img/Img";
import styles from "./FlatLinks.module.scss";

type FlatLinksProps = {
  items: IFlatNews[];
};

const FlatLinks: FC<FlatLinksProps> = ({ items }) => {
  return (
    <div className={styles.flatLinks}>
      {items.map((item) => (
        <div key={item.id} className={styles.flatLinksItem}>
          <Img
            src={item.img}
            alt={item.img}
            link={item.link}
            description={item.description}
            screen
            rounded
          />
        </div>
      ))}
    </div>
  );
};

export default FlatLinks;
