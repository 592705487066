import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import Layout from "./layouts/MainLayout/Layout";
import EventPage from "./pages/EventPage/EventPage";
import HomePage from "./pages/HomePage/HomePage";
import EventsPage from "./pages/EventsPage/EventsPage";
import EventDay from "./pages/EventDayPage/EventDay";
import NewsPage from "./pages/NewsPage/NewsPage";
import NewsListPage from "./pages/HomePage/NewsList/NewsList";
import MapPage from "./pages/MapPage/MapPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import PeoplePage from "./pages/PeoplePage/PeoplePage";
import CityPage from "./pages/CityPage/CityPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import DigitalPage from "./pages/DigitalPage/DigitalPage";
import LifePage from "./pages/LifePage/LifePage";
import { generateRoutes } from "./utils/generateRoutes";
import { cityPageState } from "./pages/CityPage/stateCityPage";
import { lifePageState } from "./pages/LifePage/stateLifePage";
import { serviceState } from "./pages/ServicePage/stateServicePage";
import { stateDigitalPage } from "./pages/DigitalPage/stateDigitalPage";
import { oberwartLayoutState } from "./states/oberwar";
import {stateHomepage} from "./pages/HomePage/stateHomePage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";


function App() {
  const root = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/"  element={<Layout layoutProps={oberwartLayoutState} />}>
          <Route index path="/"  element={<HomePage />} />
          {/* Child pages of the main page */}
          {generateRoutes(stateHomepage.boxLinks).map((page) => (<Route key={page.path} path={page.path} element={page.component} />))}
          {stateHomepage.other && generateRoutes(stateHomepage.other).map((page) => (<Route  key={page.path} path={page.path} element={page.component} />))}
          {/* Child pages of the main page */}
          <Route path="/events"  element={ <EventsPage /> } />
          <Route path="/events/:event"  element={<EventPage />}/>
          <Route path="/timetable" element={<EventDay />} />
          <Route path="/news/:news" element={<NewsPage />} />
          <Route path="/news-list" element={<NewsListPage />} />
          <Route path="/people" element={<PeoplePage />} />


            {/*<Route path="/stadt" element={ <CityPage items={cityPageState}/> } />*/}
          {/* Child pages of the City page */}
          {generateRoutes(cityPageState).map((page) => (<Route  key={page.path} path={page.path} element={page.component}/>))}
          {/* Child pages of the City page */}

            {/*<Route path="/leben" element={<LifePage items={lifePageState}/>} />*/}
          {/* Child pages of the Life page */}
          {generateRoutes(lifePageState).map((page) => (<Route  key={page.path} path={page.path} element={page.component}/>))}
          {/* Child pages of the Life page */}

            {/*<Route  path="/service" element={ <ServicePage items={serviceState}/> } />*/}
          {/* Child pages of the Service page */}
          {generateRoutes(serviceState).map((page) => (<Route  key={page.path} path={page.path} element={page.component}/>))}
          {/* Child pages of the Service page */}
            {/*<Route  path="/digital" element={ <DigitalPage items={stateDigitalPage} /> } />*/}
          {/* Child pages of the Service page */}
          {generateRoutes(stateDigitalPage).map((page) => (<Route  key={page.path} path={page.path} element={page.component}/>))}
          {/* Child pages of the Service page */}
          <Route  path="/map-oberwart" element={<MapPage />} />
        </Route>
        <Route path="/search" element={<SearchPage />} />

      </>
    )
  );
  return (
    <div>
      <RouterProvider router={root} />
    </div>
  );
}

export default App;
//</*<Route path="*" element={ <ErrorPage/> }/>*/}