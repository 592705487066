import {IPageConfig} from "../../models/page";
import React from "react";
import styles from './NavigateUp.module.scss'
import {Link} from "react-router-dom";
import BoxLink from "../BoxLinks/BoxLinks";

interface NavigateUpProps {
    item?: IPageConfig
}

export const NavigateUp: React.FC<NavigateUpProps> = ({item}) => {


    return item ? (
        <div className={styles.up}>
            {<Link to={item.link}>{item.label}</Link>}
        </div>
    ) : <></>
}

/*
<BoxLink items={[item]}  />

 */