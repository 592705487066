import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import Typography from '../Typography/Typography';
import styles from './Button.module.scss';


type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string,
  variant: "primary",
  size?: 'small' | 'large',
  children: string;
}

const Button:React.FC<ButtonProps> = ({className, variant,children, size, ...rest}, ref) => {
  return (
    <button className={classNames(styles.button, styles[variant], className,styles[size || 'medium'], 'theme-block bordered')} {...rest} {...ref}>
      {children}
    </button>
  );
};

export default Button;