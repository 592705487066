import React, { FC } from 'react';
import Typography from '../../../components/UI/Typography/Typography';
import styles from './Person.module.scss'
import {IMember, MemberService} from "../../../service/member";
import {EFileVersion, NewsService} from "../../../service/news";
import Img from "../../../components/UI/Img/Img";

type PersonProps = {
  person: IMember
}

const Person:FC<PersonProps> = ({person}) => {
  return (
    <div className={styles.person}>
      <div className={styles.personImage}>
          {person?.uuid && (
              <Img
                  screen
                  src={MemberService.resolveProfileImage(person?.uuid) || ''}
                  alt={person.name}
              />
          )}
      </div>
      <div className={styles.personDescription}>
        <Typography bold color='black'>{`${person.name}`}</Typography>
        {person.roles && <Typography color='black'>{person.roles.map(x => x.name).join(' | ')}</Typography>}
        {person.email && <a href={`mailto:${person.email}`}> <Typography color='fume'>{person.email}</Typography> </a>}
        {person.phoneMobile && <a href={`tel:${person.phoneMobile}`} ><Typography bold color='fume'>{person.phoneMobile}</Typography> </a>}
      </div>
    </div>
  );
};

export default Person;

