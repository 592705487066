import React, {FC, ReactNode} from "react";
import BoxLink from "../../components/BoxLinks/BoxLinks";
import {IPageConfig} from "../../models/page";

type DigitalPageLayoutProps = {
    items: IPageConfig[];
    children?: ReactNode | ReactNode[];
};

const DigitalPageLayout: FC<DigitalPageLayoutProps> = ({children, items}) => {
    return (
        <div>
            <BoxLink items={items} hide={!!children}/>
            {children}
        </div>
    );
};

export default DigitalPageLayout;
