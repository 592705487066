
import React from 'react';
import { IGenericArticleProps, withGenericArticle } from '../../pages/Generic/withGenericArticle';
import { ElementarySchoolLayout } from '../ElementarySchoolLayout';


const VSActivitiesPage: React.FC<IGenericArticleProps> = ({children}) => {
  return <>{children}</>
};

export default withGenericArticle(ElementarySchoolLayout, VSActivitiesPage); 