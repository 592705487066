import React, {FC, ReactNode} from 'react';
import BoxLink from '../../components/BoxLinks/BoxLinks';
import {IPageConfig} from "../../models/page";

type SearchPageLayoutProps = {
    items: IPageConfig[]
    children?: ReactNode | ReactNode[]
}

const ServicePageLayout: FC<SearchPageLayoutProps> = ({children, items}) => {
    return (
        <div>
            <BoxLink items={items} hide={!!children}/>
            {children}
        </div>
    );
};

export default ServicePageLayout;