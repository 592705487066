
interface ICache {
    timestamp: number;

    data: any;

}

const cache: {[url: string]: ICache} = {}


async function GET_INTERNAL<T>(url: string, org: number): Promise<T|null> {
    const ret = await fetch(url, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Org': `${org}`
        })
    })
    if (ret.status === 200) {
        return ret.json();
    } else {
        return null;
    }
}

export async function GET<T>(url: string, org: number): Promise<T|null> {
    if (cache[url] && (cache[url].timestamp > (Date.now() - 10 * 1000))) {
        return cache[url].data as T;
    } else {
        cache[url] = {
            data: null,
            timestamp: Date.now()
        }
        const data = await GET_INTERNAL<T>(url, org);
        cache[url] = {
            data,
            timestamp: Date.now()
        }
        return data;
    }

}