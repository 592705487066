import React from "react";
import styles from "./ServicePage.module.scss";
import ServicePageLayout from "./ServicePageLayout";
import {IGenericArticleProps, withGenericArticle} from "../Generic/withGenericArticle";

const ServicePage: React.FC<IGenericArticleProps> = ({children}) => {
  return (
      <>{children}</>

  );
};

export default withGenericArticle(ServicePageLayout, ServicePage);
