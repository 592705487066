import React from "react";
import {CityPageLayout} from "../../CityPageLayout";
import {IGenericArticleProps, withGenericArticle} from "../../../Generic/withGenericArticle";
import Typography from "../../../../components/UI/Typography/Typography";
import PeoplePage from "../../../PeoplePage/PeoplePage";

const TrunkPage: React.FC<IGenericArticleProps> = ({children}) =>{
  return <>
    {children}
    <PeoplePage group={'AMTSLEITUNG'} />
  </>
};

export default withGenericArticle(CityPageLayout, TrunkPage);
