import React, { FC } from "react";
import Container from "../../Container/Container";
import Typography from "../../UI/Typography/Typography";
import styles from "./HeaderTop.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import SearchField from "../../UI/SearchField/SearchField";
import classNames from "classnames";
type HeaderTopProps = {
  bordered?: boolean
  logo: string;
  topName: string;
}
const HeaderTop:FC<HeaderTopProps> = ({bordered,logo,topName}) => {
  return (
    <div className={classNames(styles.headerTop, 'theme-block bordered', {bordered})}>
      <Container className={styles.headerTopContainer}>
        <div className={styles.headerTopContainerLogo}>
          <Link to='/'>
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className={styles.headerTopContainerMenu}>
          <SearchField/>
          <Typography
            className={styles.headerTopContainerMenuItem}
            color="black"
            tag="span"
            // size="xx-large"
          >
            {`${moment(new Date()).format('DD. MM. YYYY')}`}
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default HeaderTop;
