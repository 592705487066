import React from "react";
import { HeaderProps } from "../../models/header";
import { Theme } from "../../models/layout";
import HeaderSlider from "./HeaderSlider/HeaderSlider";
import HeaderTop from "./HeaderTop/HeaderTop";



const Header:React.FC<{headerProps: HeaderProps}> = ({headerProps}) => {
  return (
    <header >
      <HeaderTop logo={headerProps.logo} topName={headerProps.topName}/>
      <HeaderSlider headerSliderProps={headerProps.headerSliderProps}  />
    </header>
  );
};

export default Header;
