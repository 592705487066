import ArchivePage from "./CityChildPage/ArchivePage/ArchivePage";
import CityAdministration from "./CityChildPage/CityAdministration/CityAdministration";
import CityHall from "./CityChildPage/CityHall/CityHall";
import CityInformation from "./CityChildPage/CityInformation/CityInformation";
import CityNewsPage from "./CityChildPage/CityNewsPage/CityNewsPage";
import CommitteesPage from "./CityChildPage/CommitteesPage/CommitteesPage";
import CouncilPage from "./CityChildPage/CouncilPage/CouncilPage";
import DepartmentsPage from "./CityChildPage/DepartmentsPage/DepartmentsPage";
import EconomicYardPage from "./CityChildPage/EconomicYardPage/EconomicYardPage";
import MartinWart from "./CityChildPage/MartinWart/MartinWart";
import MayorPage from "./CityChildPage/MayorPage/MayorPage";
import NoticeBoardPage from "./CityChildPage/NoticeBoardPage/NoticeBoardPage";
import PoliticsPage from "./CityChildPage/PoliticsPage/PoliticsPage";
import RegistryOfficePage from "./CityChildPage/RegistryOfficePage/RegistryOfficePage";
import TrunkPage from "./CityChildPage/TrunkPage/TrunkPage";
import {IPageConfig} from "../../models/page";
import CityPage from "./CityPage";

export const cityPageState: IPageConfig[] = [
      {
        uuid: '616C1F7F-A2F1-4B9D-A52E-4D75DCB106EC',
        link: "/stadt",
        img: '',
        label: "Stadt",
        component:  (items, data, parent) => <CityPage parent={parent} items={items} data={data}/>,
        children: [

          {
            uuid: 'EC3533D3-1E52-46FB-B69B-00D7B141F846',
            link: "/stadt/stadtinformation-geschichte",
            label: "Stadt- information & Geschichte",
            img: "/icons/city-information-history.svg",
            content: "",
            component: (items, data, parent) => <CityInformation parent={parent} items={items} data={data}/>
          },
          {
            uuid: 'EBAAA25D-77D7-4B09-BD98-056537F409A9',
            link: "/stadt/martin-wart",
            label: "St. Martin/Wart",
            img: "/icons/martin-wart.svg",
            content: "",
            component: (items, data, parent) => <MartinWart parent={parent} items={items} data={data}/>
          },
          {
            uuid: 'F454ACA8-7035-4E8A-877A-A64A88D8AFEA',
            link: "/stadt/stadtverwaltung",
            label: "Stadtverwaltung",
            img: "/icons/city-administration.svg",
            children: [
              {
                uuid: 'FEDB49CB-49E3-4954-A436-85537391B8CA',
                link: "/stadt/stadtverwaltung/rathaus",
                label: "Rathaus",
                img: "/icons/city-hall.svg",
                children: [
                  {
                    uuid: 'AFA9205F-AF81-4BDD-B787-CE3016AA8C49',
                    link: "/stadt/stadtverwaltung/rathaus/amtsleitung",
                    label: "Amtsleitung",
                    img: "/icons/trunk.svg",
                    content: "",
                    component: (items, data, parent) => <TrunkPage parent={parent} items={items} data={data}/>
                  },
                  {
                    uuid: '40A4C785-B5DE-4BC5-80C9-CD5459D415F5',
                    link: "/stadt/stadtverwaltung/rathaus/abteilungen",
                    label: "Abteilungen",
                    img: "/icons/departments.svg",
                    content: "",
                    component: (items, data) => <DepartmentsPage items={items} data={data}/>
                  },
                ],
                content: "",
                component: (items, data, parent) => <CityHall parent={parent} items={items} data={data}/>
              },
              {
                uuid: 'F37201BC-E640-45DB-9FD0-DA30CF2B181A',
                link: "/stadt/stadtverwaltung/wirtschaftshof",
                label: "Wirtschaftshof",
                img: "/icons/economic-yard.svg",
                content: "",
                component: (items, data, parent) => <EconomicYardPage parent={parent} items={items} data={data}/>
              },
            ],
            content: "",
            component: (items, data, parent) => <CityAdministration parent={parent} items={items} data={data}/>
          },
          {
            uuid: '79AFF298-9058-4563-ADC8-5D56DFF001DF',
            link: "/stadt/standesamtsverband",
            label: "Standesamts- verband",
            img: "/icons/registry-office.svg",
            content: "",
            component: (items, data, parent) => <RegistryOfficePage parent={parent} items={items} data={data}/>
          },
          {
            uuid: '844FA0A2-B342-4214-B072-68A172C28B4A',
            link: "/stadt/politik",
            label: "Politik",
            img: "/icons/politics.svg",
            content: "",
            component: (items, data, parent) => <PoliticsPage parent={parent} items={items} data={data}/>,
            children: [
              {
                uuid: 'D7AFD952-69B1-4FD9-B0EA-C97D4A42FCA6',
                link: "/stadt/politik/buergermeister",
                label: "Bürgermeister",
                img: "/icons/mayor.svg",
                content: "",
                component: (items, data, parent) => <MayorPage parent={parent} items={items} data={data}/>,
              },
              {
                uuid: 'D22F9A44-A323-48B1-8232-38B04309B973',
                link: "/stadt/politik/gemeinderat",
                label: "Gemeinderat",
                img: "/icons/council.svg",
                content: "",
                component: (items, data, parent) => <CouncilPage parent={parent} items={items} data={data}/>,
              },
              {
                uuid: 'B7A09561-3EE8-495E-8495-69097D05E195',
                link: "/stadt/politik/ausschuesse",
                label: "Ausschüsse",
                img: "/icons/committees.svg",
                content: "",
                component: (items, data, parent) => <CommitteesPage parent={parent} items={items} data={data}/>,
              },
            ],
          },
          {
            uuid: '29447731-6BC7-478B-947D-380404466A71',
            link: "/stadt/neuigkeiten",
            label: "Neuigkeiten",
            img: "/icons/news.svg",
            content: "",
            component: (items, data, parent) => <CityNewsPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: '63B463AA-30B6-4F2E-9234-5DDC836E40CD',
            link: "/stadt/amtstafel",
            label: "Amtstafel",
            img: "/icons/notice-board.svg",
            content: "",
            component: (items, data, parent) => <NoticeBoardPage parent={parent} items={items} data={data}/>,
          },
          {
            uuid: '3E4CA0C5-3E5A-402B-975F-D6C1B598023E',
            link: "/stadt/digitales-archiv",
            label: "Digitales Archiv",
            img: "/icons/archive.svg",
            content: "",
            component: (items, data, parent) => <ArchivePage parent={parent} items={items} data={data}/>,
          },
        ]
      }
];
