import classNames from "classnames";
import React, { createContext, ReactNode, useCallback, useLayoutEffect } from "react";
import Container from "../components/Container/Container";
import useLocalStorage from "../hooks/useLocalStorage";
import { OrganizationType } from "../models/organization";
import styles from "./Theme.module.scss";

type ThemeProps = {
  children: ReactNode | ReactNode[];
};
export type ThemeState = {
  contrast: "BLACK_WHITE" | "BLACK_YELLOW" | "YELLOW_BLACK" | 'DEFAULT';
  fontSize: number,
  organization: OrganizationType
};
const initialState:ThemeState = {
  contrast: 'DEFAULT',
  fontSize: 16,
  organization: process.env.REACT_APP_ORGANIZATION as OrganizationType || 'oberwart', 

}
export const ThemeContext = createContext<ThemeState>(initialState)
const Theme: React.FC<ThemeProps> = ({ children }) => {
  const [theme, setTheme] = useLocalStorage<ThemeState | undefined>("theme", initialState);

  console.log('process.env.REACT_APP_ORGANIZATION', process.env.REACT_APP_ORGANIZATION)

  const handleContrast = useCallback(() => {
    if(theme) {
      console.log('theme', theme)
      document.documentElement.setAttribute('data-theme', theme.contrast)
      document.documentElement.setAttribute('data-organization', theme.organization)
    }
  }, [theme])


  const handleFontSize = useCallback(() => {
   document.documentElement.style.fontSize = `${theme?.fontSize}px`
  }, [theme?.fontSize])

  useLayoutEffect(() => {
    handleContrast()
    handleFontSize()
  }, [handleContrast, handleFontSize])


  return (
    <div className={classNames(styles.theme, theme?.contrast )}>
      <ThemeContext.Provider value={theme ?? initialState}>
        <Container className={styles.themeContainer}>
          <ul className={classNames(styles.themeList)}>
            <li
              className={classNames(styles.themeListItem)}
              title="Aktiviere Standardmodus."
              onClick={() => theme && setTheme({...theme, contrast:'DEFAULT'})}
            >
              Standardmodus
            </li>
            <li
              className={classNames(styles.themeListItem, "black-white")}
              title="Hoher Kontrast Schwarz Weiss"
              onClick={() => theme && setTheme({...theme, contrast:'BLACK_WHITE'})}
            >
              AA
            </li>
            <li
              className={classNames(styles.themeListItem, "black-yellow")}
              title="Hoher Kontast Schwarz Gelb"
              onClick={() => theme && setTheme({...theme, contrast:'BLACK_YELLOW'})}
            >
              AA
            </li>
            <li
              className={classNames(styles.themeListItem, "yellow-black")}
              title="Hoher Kontrast Gelb Schwarz"
              onClick={() => theme && setTheme({...theme, contrast:'YELLOW_BLACK'})}
            >
              AA
            </li>
            <li
              className={classNames(styles.themeListItem)}
              title="Schrift kleiner"
              onClick={() => {
                if(theme && theme.fontSize > 12) {
                  setTheme({...theme, fontSize: theme.fontSize - 2})
                }
              }}
            >
              A -
            </li>
            <li
              className={classNames(styles.themeListItem)}
              title="Standardschriftgröße"
              onClick={() => theme && setTheme({...theme, fontSize: 16})}
            >
              A
            </li>
            <li
              className={classNames(styles.themeListItem)}
              title="Schrift größer"
              onClick={() => {
                if(theme && theme.fontSize < 22) {
                  setTheme({...theme, fontSize: theme.fontSize + 2})
                }
              }}
            >
              A +
            </li>
          </ul>
        </Container>
        {children}

      </ThemeContext.Provider>
    </div>
  );
};

export default Theme;
