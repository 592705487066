import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppSchool from "./AppSchool/AppSchool";
import AppPTS from "./AppPTS/AppPTS";
import AppElementarySchool from "./AppElementarySchool/AppElementarySchool";
import AppGeneralSchool from "./AppGeneralSchool/AppGeneralSchool";
import AppEconomicYard from "./AppEconomicYard/AppEconomicYard";
import AppCreche from "./AppCreche/AppCreche";
import AppKindergarten from "./AppKindergarten/AppKindergarten";
import AppAfternoonCare from "./AppAfternoonCare/AppAfternoonCare";
import AppROA from "./AppROA/AppROA";
import Theme from "./ThemeWrapper/Theme";


const selectOrg = () => {
  switch (process.env.REACT_APP_ORGANIZATION) {
    case 'oberwart':
      return  <App />
    case 'school':
      return  <AppSchool/>
    case 'pts':
      return <AppPTS/>
    case 'elementarySchool':
      return <AppElementarySchool/>
    case 'generalSchool':
      return <AppGeneralSchool/>
    case 'economicYard':
      return <AppEconomicYard/>
    case 'creche':
      return <AppCreche/>
    case 'kindergarten':
      return <AppKindergarten/>
    case 'afternoonCare':
      return <AppAfternoonCare/>
    case 'roa':
      return <AppROA/>
    default:
    return  <App/>
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Theme>
      {selectOrg()}
    </Theme>
  </React.StrictMode>
);

reportWebVitals();
